import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase";

const getLevels = async () => {
  try {
    const levelsCollection = collection(db, "levels");

    const levelsQuery = query(levelsCollection, orderBy("levelCode", "asc"));
    const levelsSnapshot = await getDocs(levelsQuery);

    const levels: any[] = levelsSnapshot.docs.map((doc) => {
      const dataWithId = doc.data() as any;
      return { id: doc.id, ...dataWithId };
    });

    return { status: true, levels };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Failed to get levels", error };
  }
};

const getLevelsFromLevelCode = async (startLevelCode: number) => {
  try {
    const levelsCollection = collection(db, "levels");

    const levelsQuery = query(
      levelsCollection,
      limit(12),
      where("levelCode", ">=", startLevelCode),
      orderBy("levelCode", "asc")
    );

    const levelsSnapshot = await getDocs(levelsQuery);

    const levels: any[] = levelsSnapshot.docs.map((doc) => {
      const dataWithId = doc.data() as any;
      return { id: doc.id, ...dataWithId };
    });

    return { status: true, levels };
  } catch (error) {
    console.log("Error:", error);
    return { status: false, message: "Failed to get levels", error };
  }
};

const getAllItemsForUser = async (userLevelCode: number) => {
  try {
    const levelsCollection = collection(db, "levels");
    const levelsQuery = query(
      levelsCollection,
      where("levelCode", "==", userLevelCode)
    );
    const levelsSnapshot = await getDocs(levelsQuery);

    const allItems = [];

    levelsSnapshot.forEach((levelDoc) => {
      const categoriesArray = levelDoc.data().categories;

      if (Array.isArray(categoriesArray) && categoriesArray.length > 0) {
        categoriesArray.forEach((category) => {
          const categoryNameHr = category.categoryNameHr;
          const categoryNameEn = category.categoryNameEn;

          const items = category.items.map((item) => ({
            id: item.id,
            ...item,
            categoryNameHr,
            categoryNameEn,
          }));

          allItems.push(...items);
        });
      }
    });

    console.log(
      "NAJV",
      allItems.map((item) => item.itemCode)
    );

    return { status: true, items: allItems };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Failed to get items", error };
  }
};

const levelsService = {
  getLevels,
  getLevelsFromLevelCode,
  getAllItemsForUser,
};

export default levelsService;
