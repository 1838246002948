import React, { createContext, useState, useEffect } from "react";

interface LanguageContextType {
  language: string | null;
  t: (key: string) => string;
  updateLanguage: (newLanguage: string) => void;
}

export const LanguageContext = createContext<LanguageContextType>({
  language: null,
  t: (key) => key,
  updateLanguage: () => {},
});

interface LanguageProviderProps {
  children: React.ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState<string | null>(null);
  const [translations, setTranslations] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const initializeLanguage = () => {
      const storedLanguage = localStorage.getItem("appLanguage");
      console.log(storedLanguage);
      if (storedLanguage) {
        setLanguage(storedLanguage);
      } else {
        const deviceLanguage = navigator.language.slice(0, 2);
        const supportedLanguages = ["en", "hr"];
        const supportedLanguage = supportedLanguages.includes(deviceLanguage)
          ? deviceLanguage
          : "en";
        setLanguage(supportedLanguage);
      }
    };
    initializeLanguage();
  }, []);

  useEffect(() => {
    const storeLanguageLocally = () => {
      if (language) {
        localStorage.setItem("appLanguage", language);
      }
    };

    const translationsData = {
      en: {
        level: "Level",
        code: "Code",
        password: "Password",
        mobileNumber: "Mobile number",
        welcomeScreenTitle: "Start piling your",
        emailAndPasswordAreMandatory: "Email and password are mandatory.",
        invalidCredentials:
          "Invalid credentials. Please check your email and password.",
        loginFailed:
          "Login failed. Please check your credentials and try again.",
        signUpFailed:
          "Registration failed. Please check your credentials and try again.",
        register: "Register",
        privacyPolicy: "Privacy policy",
        withPrivacy: "Privacy policy",
        termsAndConditions: "Terms and Conditions",
        byRegisteringText: "By registering, you are agreeing to ",
        privacyPolicyAndTermsAndConditions:
          "privacy policy and terms and conditions.",
        and: "and",
        dontHaveAnAccount: "Don't have an account?",
        signUp: "Sign up",
        login: "Sign in",
        signInWithGoogle: "Sign in with Google",
        signUpWithGoogle: "Sign up with Google",
        signInWithFacebook: "Sign in with Facebook",
        signUpWithFacebook: "Sign up with Facebook",
        signInWithApple: "Sign in with Apple",
        signUpWithApple: "Sign up with Apple",
        emailAlreadyExistsWithDifferentCredential:
          "Email already used for registering.",
        forgotPassword: "Forgot password?",
        city: "City",
        street: "Street",
        postalCode: "Postal Code",
        save: "Save",
        emailIsRequired: "Email is required",
        cityIsRequired: "City is required",
        streetIsRequired: "Street is required",
        postalCodeIsRequired: "Postal Code is required",
        accountCreation: "Account creation",
        welcomeBack: "Welcome back",
        doYouHaveAccount: "You have an account?",
        signIn: "Sign in",
        registration: "Register",
        resetYourPassword: "Reset your password",
        resetPassword: "Reset password",
        lastNameContainsInvalidCharacters:
          "Last name contains invalid characters.",
        nameContainsInvalidCharacters: "Name contains invalid characters.",
        emailCannotBeEmpty: "Email cannot be empty.",
        passwordCannotBeEmpty: "Password cannot be empty.",
        phoneNumberCannotBeEmpty: "Phone number cannot be empty.",
        notSubbed: "Not subscribed",
        emailResetMessage:
          "Email for changing the password is successfully sent!",
        checkYourEmail: "Check your email for further steps.",
        changingEmailError:
          "Email for changing the password failed to be sent. Please try again.",
        pleaseFillAll: "Please fill all fields.",
        pleaseFillAllFields:
          "Please fill all fields and agree to the Privacy and Policy and Terms and Conditions.",
        error: "Error",
        emailError:
          "The email address is already in use. Please use a different email.",
        emailNotValidError:
          "The email address you entered is not valid. Please check your entry.",
        passwordMissing: "Password is missing.",
        passwordLength: "Password should be at least 6 characters.",
        namePlaceholder: "Name",
        lastNamePlaceholder: "Last name",
        chooseSubscription: "Choose subscription:",
        choose: "Choose",
        month: "month",
        chooseLevelAmount: "Choose level amount:",
        amount: "Amount:",
        notSubscribed: "Unavailable: No subscription!",
        joined: "Joined SurvStash:",
        warehouseLocation: "Warehouse location:",
        bonusLevelFromPoints: "Bonus Level from Points:",
        rewardPoints: "Reward Points:",
        enterEmail: "Enter your email",
        enterPassword: "Enter your password",
        noDate: "No date",
        date: "Date:",
        oneTimePayment: "One Time Payments",
        subscription: "Subscription",
        yourStashIs: "Your Stash is:",
        availableCategories: "Categories:",
        filterByNewsType: "Filter by news type",
        noCategoriesAdded: "No categories available",
        homeScreenTitle: "Home",
        change: "Change",
        enter: "Enter",
        noStashItems: "No stash items in your current level",
        noStashItemsDescription:
          "Wait for your next level gain through subscription or make the wait shorter by purchasing our one time level payment or by upgrading the subscription tier by using one of the buttons below.",
        newsTitle: "News",
        nextLevel: "Next level:",
        subscriptionBenefitBasic: "(One level every 2 months)",
        subscriptionBenefitAdvanced: "(One level every month)",
        saving: "Saving in progress...",
        oneL: "One Level",
        twoL: "Two Levels",
        threeL: "Three Levels",
        fourL: "Four Levels",
        oneLevel:
          "One level which is equal to one month of subscription in Advanced tier.",
        twoLevel:
          "Two levels which are equal to two months of subscription in Advanced tier.",
        threeLevel:
          "Three levels which are equal to three months of subscription in Advanced tier.",
        fourLevel:
          "Four levels which are equal to four months of subscription in Advanced tier.",
        subscriptionBenefitExpert: "(Two levels every month)",
        noSubscription: "To receive levels and items a subscription is needed",
        availableNow: "Available now!",
        availableOn: "Available on: ",
        pictureIsJustIllustration:
          "The picture is only illustrative of the item that is in stock. Brand, manufacturer, packaging and other elements may vary.",
        myStash: "My Stash",
        productComposition: "Composition:",
        registrationFailed: "The email is invalid or already in use.",
        productDurability: "Durability rating:",
        productWeightAndDimensions: "Weight and dimensions:",
        productRecommendedUsage: "Recommended usage:",
        productMaintenanceTips: "Maintenance tips:",
        deliveryTitle: "Delivery",
        currentLevel: "Current level",
        deliver: "Delivery",
        noDeliveryTitle: "You do not have delivery.",
        noDeliveryDescription: "There is no delivery description.",
        deliveryItemUnavailable: "You currently don't have delivery item.",
        deliveryTitleField: "Delivery title",
        deliveryDescriptionField: "Delivery description",
        deliveryAddressField: "Delivery address",
        deliveryEnterAddress: "Enter delivery address.",
        deliveryWarning:
          "Choosing to deliver items will result in LEVEL going back to 0.",
        important: "Important",
        profile: "Profile",
        loading: "Registering, please wait...",
        personalData: "Personal data",
        dataSavedSuccessfully: "Data saved successfuly!",
        dataSaveFailed: "Data is not saved.",
        confirmSubmission: "Confirm Delivery",
        confirmPassword: "Confirm Password",
        confirmDelete: "Confirm Delete",
        deleting: "Deleting...",
        cancelSubmission: "Cancel",
        pay: "Pay",
        changeSubscription: "Change subscription",
        paymentLog: "Payment Log",
        customerService: "Customer Service",
        faq: "FAQ",
        dataPrivacy: "Privacy Policy",
        legalTerms: "Terms and Conditions",
        withLegal: "Terms and Conditions",
        logOut: "Log Out",
        deleteAccount: "Delete Account",
        areYouSure: "Do you want to continue?",
        allRightsReserved: "All rights reserved",
        or: "OR",
        sendUsEmail:
          "Send us an email and we will respond in shortest time possible",
        writeYourInquiry: "Write your Inquiry here",
        sendInquiry: "Send Inquiry",
        new: "New!",
        noLevelFound: "No level found with code",
        noPaymentsFound: "No payments found",
        paymentsStart:
          "To get started making payments and using SurvStash, feel free to use one of the options below to start a subscription or make a one-time payment.",
      },
      hr: {
        level: "Nivo",
        code: "Kod",
        password: "Zaporka",
        mobileNumber: "Broj mobitela",
        welcomeScreenTitle: "Krenite gomilati svoje zalihe",
        emailAndPasswordAreMandatory: "Email i zaporka su obavezna polja.",
        invalidCredentials:
          "Neuspješna prijava. Molimo provjerite unešen email i zaporku.",
        loginFailed:
          "Neuspješna prijava. Molimo provjerite unešen email i zaporku.",
        signUpFailed:
          "Registracija neuspješna. Molimo provjerite unešene podatke.",
        register: "Registriraj se",
        privacyPolicy: "Politika privatnosti",
        termsAndConditions: "Uvjeti korištenja",
        byRegisteringText: "Registracijom se slažete s ",
        privacyPolicyAndTermsAndConditions:
          "politikom privatnosti podataka i uvjetima korištenja.",
        and: "i",
        dontHaveAnAccount: "Nemate račun?",
        signUp: "Registriraj se",
        login: "Prijavi se",
        signInWithGoogle: "Prijavi se s Google",
        signUpWithGoogle: "Registriraj se s Google",
        signInWithFacebook: "Prijavi se s Facebook",
        signUpWithFacebook: "Registriraj se s Facebook",
        signInWithApple: "Prijavi se s Apple",
        signUpWithApple: "Registriraj se s Apple",
        emailAlreadyExistsWithDifferentCredential:
          "Email je već iskorišten za registriranje.",
        forgotPassword: "Zaboravljena zaporka?",
        city: "Grad",
        street: "Ulica",
        postalCode: "Poštanski broj",
        save: "Spremi",
        emailIsRequired: "Email je obavezan",
        cityIsRequired: "Grad je obavezan",
        streetIsRequired: "Ulica je obavezna",
        postalCodeIsRequired: "Poštanski broj je obavezan",
        accountCreation: "Izrada računa",
        welcomeBack: "Dobrodošli natrag",
        doYouHaveAccount: "Već imate račun?",
        signIn: "Prijava",
        registration: "Registracija",
        resetYourPassword: "Resetirajte Vašu zaporku",
        resetPassword: "Resetiraj zaporku",
        notSubbed: "Nema pretplate",
        dataSavedSuccessfully: "Podaci su uspješno spremljeni!",
        dataSaveFailed: "Podaci se nisu uspješno spremili.",
        loading: "Registracija u tijeku, molimo pričekajte...",
        emailResetMessage: "Email za promjenu zaporke uspješno je poslan!",
        checkYourEmail: "Provjerite Vaš email za daljnje korake.",
        changingEmailError:
          "Neuspjelo slanje emaila za promjenu zaporke. Molimo pokušajte ponovno.",
        error: "Greška",
        emailError:
          "Unešena email adresa je već u uporabi. Molimo koristite drugi email za registraciju.",
        emailNotValidError:
          "Email kojeg ste unijeli nije ispravan. Molimo provjerite Vaš unos.",
        passwordMissing: "Nedostaje zaporka.",
        passwordLength: "Zaporka mora sadržavati barem 6 znakova.",
        namePlaceholder: "Ime",
        lastNamePlaceholder: "Prezime",
        confirmSubmission: "Potvrdi dostavu",
        confirmPassword: "Potvrdi lozinku",
        confirmDelete: "Potvrdi brisanje",
        deleting: "Brisanje...",
        lastNameContainsInvalidCharacters: "Prezime sadrži nevažeće znakove.",
        nameContainsInvalidCharacters: "Ime sadrži nevažeće znakove.",
        emailCannotBeEmpty: "Email ne može biti prazan.",
        passwordCannotBeEmpty: "Zaporka ne može biti prazna.",
        phoneNumberCannotBeEmpty: "Broj mobitela ne može biti prazan.",
        cancelSubmission: "Odustani",
        pay: "Plati",
        areYouSure: "Želite li nastaviti?",
        chooseSubscription: "Odaberi pretplatu:",
        choose: "Odaberi",
        withPrivacy: "Politikom privatnosti",
        withLegal: "uvjetima korištenja",
        chooseLevelAmount: "Odaberi količinu nivoa:",
        amount: "Iznos:",
        oneL: "Jedan nivo",
        twoL: "Dva nivoa",
        threeL: "Tri nivoa",
        fourL: "Četiri nivoa",
        oneLevel:
          "Jedan nivo koji je jednake vrijednosti kao i jedan mjesec pretplate na Advanced razini.",
        twoLevel:
          "Dva nivoa koja su jednake vrijednosti kao i dva mjeseca pretplate na Advanced razini.",
        threeLevel:
          "Tri nivoa koja su jednake vrijednosti kao i tri mjeseca pretplate na Advanced razini.",
        fourLevel:
          "Četiri nivoa koja su jednake vrijednosti kao i četiri mjeseca pretplate na Advanced razini.",
        notSubscribed: "Nedostupno: Niste pretplaćeni!",
        saving: "Spremanje u tijeku...",
        warehouseLocation: "Lokacija skladišta:",
        bonusLevelFromPoints: "Dobiven level zbog bodova:",
        rewardPoints: "Nagradni bodovi:",
        enterEmail: "Unesite Vaš e-mail",
        enterPassword: "Unesite Vašu lozinku",
        joined: "Datum registracije:",
        noDate: "Nema datuma",
        date: "Datum:",
        oneTimePayment: "Jednokratno plaćanje",
        subscription: "Pretplata",
        yourStashIs: "Vaš Stash je:",
        availableCategories: "Kategorije:",
        filterByNewsType: "Filtriranje po tipu vijesti",
        noCategoriesAdded: "Nema dostupnih kategorija",
        homeScreenTitle: "Početna",
        change: "Promijeni",
        enter: "Kreni",
        noStashItems: "Vaš Stash je prazan u trenutnom nivou.",
        noStashItemsDescription:
          "Potrebno je pričekati na dobivanje sljedećeg nivoa kroz pretplatu ili uplatiti jednokratnu uplatu za nivo ili promijeniti nivo pretplate koristeći neki od gumbeva ispod.",
        newsTitle: "Vijesti",
        nextLevel: "Sljedeći nivo:",
        subscriptionBenefitBasic: "(Jedan nivo svaka 2 mjeseca)",
        subscriptionBenefitAdvanced: "(Jedan nivo svaki mjesec)",
        pleaseFillAll: "Ispunite sva polja.",
        pleaseFillAllFields:
          "Ispunite sva polja i složite se s Politikom privatnosti i Uvjetima.",
        subscriptionBenefitExpert: "(Dva nivoa svaki mjesec)",
        noSubscription:
          "Za dobivanje nivoa i punjenje Stash-a potrebna je pretplata.",
        availableNow: "Dostupno sada!",
        registrationFailed: "Email je neispravan ili se već koristi.",
        availableOn: "Dostupno na: ",
        pictureIsJustIllustration:
          "Slika predstavlja samo ilustrativno predmet koji je na zalihi. Brand, proizvođač, pakiranje i ostali elementi mogu se razlikovati.",
        myStash: "Moj Stash",
        productComposition: "Sastav:",
        productDurability: "Trajanje proizvoda:",
        productWeightAndDimensions: "Težina i dimenzije:",
        productRecommendedUsage: "Preporučena uporaba:",
        productMaintenanceTips: "Savjeti za očuvanje:",
        deliveryTitle: "Isporuka",
        currentLevel: "Trenutni nivo",
        deliver: "Dostavi",
        noDeliveryTitle: "Nemate dostavu.",
        noDeliveryDescription: "Nema opisa dostave.",
        deliveryItemUnavailable: "Trenutno nemate dostavu u svojem Stashu.",
        deliveryTitleField: "Naslov dostave",
        deliveryDescriptionField: "Opis dostave",
        deliveryAddressField: "Adresa dostave",
        deliveryEnterAddress: "Unesite adresu dostave",
        deliveryWarning:
          "Odabir dostave će rezultirati vraćanjem nivoa na nivo 0.",
        important: "Važno",
        month: "mjesec",
        profile: "Profil",
        personalData: "Osobni podaci",
        changeSubscription: "Promijeni pretplatu",
        paymentLog: "Zapisi plaćanja",
        customerService: "Podrška",
        faq: "FAQ",
        dataPrivacy: "Privatnost",
        legalTerms: "Uvjeti korištenja",
        andLegalTerms: "uvjetima korištenja.",
        logOut: "Odjavi se",
        deleteAccount: "Obrišite račun",
        allRightsReserved: "Sva prava pridržana",
        or: "ILI",
        sendUsEmail:
          "Pošaljite nam e-mail, a mi ćemo odgovoriti u najkraćem mogućem roku",
        writeYourInquiry: "Napišite svoj upit ovdje",
        sendInquiry: "Pošalji upit",
        new: "Novo!",
        noLevelFound: "Nema pronađenog nivoa s kodom",
        noPaymentsFound: "Nema pronađenih uplata",
        paymentsStart:
          "Da započnete s uplatama i korištenjem SurvStash-a, slobodno iskoristite neku od donjih opcija da započnete pretplatu ili da izvršite jednokratnu uplatu.",
      },
    };
    if (language) {
      setTranslations(translationsData[language] || {});
    }
    storeLanguageLocally();
  }, [language]);

  const updateLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
  };

  const t = (key: string) => {
    if (translations[key]) {
      return translations[key];
    }
    return key;
  };

  return (
    <LanguageContext.Provider value={{ language, t, updateLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
