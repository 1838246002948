import React, { useContext, useState } from "react";
import { Pages } from "./Pages";
import { LanguageContext } from "../../utils/LanguageProvider";
import { UserDetailsContext } from "../../utils/UserDetailsProvider";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
interface PageProps {
  page: string;
  link: string;
}
export interface NavigationProps {
  pages: PageProps[];
  onClick?: () => void;
}

export const Navigation: React.FC = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { t } = useContext(LanguageContext);
  const { userDetails } = useContext(UserDetailsContext);
  const navigate = useNavigate();

  const toggleNav = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeNav = () => {
    setDropdownVisible(false);
  };

  const pagesList = [
    { page: t("homeScreenTitle"), link: "" },
    { page: t("newsTitle"), link: "news" },
    { page: t("myStash"), link: "my-stash" },
    { page: t("deliveryTitle"), link: "delivery" },
    userDetails
      ? { page: t("profile"), link: "profile" }
      : { page: t("login"), link: "login" },
  ];

  return (
    <header className="bg-white h-[90px] z-50 md:relative sticky border-b border-emerald-600 text-emerald-500 py-4 block top-0 left-0 w-full">
      <nav className=" mx-auto">
        <div
          className={`flex max-w-[920px] h-[60px] ${"justify-center"} lg:mx-auto mx-6 items-center`}>
          <div
            onClick={() => navigate("/")}
            className={`flex flex-row ${
              userDetails ? "gap-3" : "gap-5"
            } items-center md:px-8 bg-white rounded-md`}>
            <img
              className="h-[50px] cursor-pointer"
              src={Logo}
              alt="survstash"
            />
          </div>
          {/* Desktop Nav view */}
          {userDetails && (
            <ul className="hidden md:flex px-8 space-x-2">
              <Pages pages={pagesList} onClick={closeNav} />
            </ul>
          )}
        </div>
      </nav>
    </header>
  );
};
