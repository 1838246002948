import React, { useContext } from "react";
import { Button } from "../Button/Button";
import profileImage from "../../assets/profile.png";
import { LanguageContext } from "../../utils/LanguageProvider";
import { Link } from "react-router-dom";
import { UserDetailsContext } from "../../utils/UserDetailsProvider";

interface ProfileInfoProps {
    name: string,
    surname: string,
    level: number,
    subscription: string,
    dateJoined: string
    location?: string,
    rewardPoints?: string,
    bonusPoints?: string
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ name, surname, level, subscription, dateJoined, location, rewardPoints, bonusPoints }) => {
    const { t } = useContext(LanguageContext);
    const subscriptionText = !subscription ? "notSubbed" : subscription;
    return(
        <div className="flex flex-col gap-3">
            <div className="border rounded-xl flex flex-col px-6 py-5 md:flex-row justify-around shadow-xl min-h-44 bg-white p-4">
                <img src={profileImage} className="md:h-48 content-center md:w-auto h-24 w-24 mx-auto" alt="Profile" />
                <div className="md:text-left content-center">
                    <h2 className="text-xl text-emerald-600 font-semibold">{name} {surname}</h2>
                    <p>{t("level")}: <span className="text-emerald-600 font-semibold">{level}</span></p>
                    <p>{t("subscription")}: <span className="text-emerald-600 font-semibold">{t(subscriptionText)}</span></p>
                    <p>{t("joined")} <span className="text-emerald-600 font-semibold">{dateJoined}</span></p>
                    {location && <p>{t("warehouseLocation")} <span className="text-emerald-600 font-semibold">{location}</span></p>}
                    {rewardPoints && <p>{t("rewardPoints")} <span className="text-emerald-600 font-semibold">{rewardPoints}</span></p>}
                    {rewardPoints && <p>{t("bonusLevelFromPoints")} <span className="text-emerald-600 font-semibold">{bonusPoints}</span></p>}

                </div>
            </div>
            <Link to="/personal-data">
                <Button className="h-28 text-lg bg-white text-emerald-600 rounded-xl border-2 hover:bg-gray-200 border-emerald-500 w-full">{t("personalData")}</Button>
            </Link>
        </div>
    )
}