import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowRight, FaHandPointLeft, FaArrowDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../utils/LanguageProvider";
import { NewBadge } from "../NewBadge/NewBadge";
import { calculateAvailableDate } from "../../utils/dateUtils";
import { UserDetailsContext } from "../../utils/UserDetailsProvider";
import { useGetActiveSubscription, useGetCustomer } from "../../services";

export interface ItemProps {
  itemCode: string;
  itemCustom1: string;
  itemCustom2: string;
  itemCustom3: string;
  itemCustom4: string;
  itemDescriptionEn: string;
  itemDescriptionHr: string;
  itemNameEn: string;
  itemNameHr: string;
  itemPicture: string;
  itemTypeEn: string;
  itemTypeHr: string;
  newItem: string;
  quantity: number;
}

export interface CategoryProps {
  categoryCode: string;
  categoryCustom1: string;
  categoryCustom2: string;
  categoryDescriptionEn: string;
  categoryDescriptionHr: string;
  categoryNameEn: string;
  categoryNameHr: string;
  categoryPicture: string;
  items: ItemProps[];
  newCategory: string;
}

export interface CardLevelProps {
  levelCode: string;
  index: number;
  categories: CategoryProps[];
  levelCustom1: string;
  levelCustom2: string;
  levelDescriptionEn: string;
  levelDescriptionHr: string;
  levelNameEn: string;
  levelNameHr: string;
  levelPicture: string;
}

export const CardLevel: React.FC<CardLevelProps> = ({
  levelCode,
  categories,
  index,
}) => {
  const { t, language } = useContext(LanguageContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { data: activeSubscription, refetch } = useGetActiveSubscription({
    customerId: userDetails?.uid,
  });

  const [level, setLevel] = useState(userDetails?.level || 0);

  useEffect(() => {
    if (userDetails?.level) {
      setLevel(userDetails?.level);
    }
  }, [levelCode, userDetails?.level]);

  const subscription = activeSubscription?.role || "";
  const isLevelHigher = parseInt(levelCode) > level;
  const timestamp = activeSubscription?.created.seconds;
  const isFirstLevel = parseInt(levelCode) === level;

  const getAvailableDate = (index: number) => {
    console.log("HBhbhb", index);
    return calculateAvailableDate(
      subscription,
      activeSubscription?.current_period_start,
      index,
      userDetails
    );
  };

  let messageAvailable = t("notSubscribed");
  if (!subscription) {
    messageAvailable = isFirstLevel ? t("availableNow") : t("notSubscribed");
  } else {
    messageAvailable =
      parseInt(levelCode) !== 0
        ? activeSubscription?.created
          ? `${t("availableOn")} ${getAvailableDate(parseInt(levelCode))}`
          : t("notSubscribed")
        : t("availableNow");
  }

  const { data: uniqueCustomer, refetch: customerRefetch } = useGetCustomer({
    customerId: userDetails?.uid,
  });

  const updateLevel = async () => {
    try {
      const customerId = uniqueCustomer.id;
      const result = await fetch(
        "https://us-central1-survstash-f265b.cloudfunctions.net/updateUserLevelManually",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: customerId,
          }),
        }
      );
      const responseData = await result.json();
      console.log("Cloud Function Response:", responseData);
    } catch (error) {
      console.error("Error calling Cloud Function:", error);
    }
  };

  useEffect(() => {
    if (uniqueCustomer?.id) {
      const fetchData = async () => {
        await updateLevel();
      };

      fetchData();
    }
  }, [uniqueCustomer?.id]);

  useEffect(() => {
    if (userDetails?.uid) {
      refetch();
    }
  }, [userDetails?.uid, refetch]);

  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contentEl = contentRef.current;

    if (contentEl && contentEl.scrollHeight > contentEl.clientHeight) {
      setShowScrollIndicator(true);
    }

    const handleScroll = () => {
      setShowScrollIndicator(false);
    };

    if (contentEl) {
      contentEl.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentEl) {
        contentEl.removeEventListener("scroll", handleScroll);
      }
    };
  }, [categories]);

  return (
    <div
      className={`bg-emerald-500 flex flex-col font-semibold mx-4 mb-4 md:mb-8 justify-center rounded-xl h-auto shadow-xl ${
        isLevelHigher ? "bg-opacity-50" : ""
      }`}
    >
      <div className="flex flex-row gap-0 items-center justify-around">
        <div className={`flex flex-col h-36 basis-1/4 justify-end`}>
          <p className="text-white text-sm md:text-lg md:pt-3 pb-1 mx-3">
            {index !== 0
              ? activeSubscription?.created
                ? `${t("availableOn")} ${calculateAvailableDate(
                    activeSubscription?.role,
                    activeSubscription?.current_period_start,
                    index,
                    userDetails
                  )}`
                : t("notSubscribed")
              : t("availableNow")}
          </p>
          {Number(levelCode) === 0 ? (
            <div
              className={`bg-gray-200 min-w-[110px] content-center h-16 m-3 mr-1 shadow-xl p-2 rounded-lg`}
            >
              <div className="flex flex-col md:flex-row justify-evenly h-full">
                <h2 className="md:text-3xl my-auto">
                  {t("level")} {levelCode}
                </h2>
              </div>
            </div>
          ) : (
            <Link
              className={`bg-gray-200 content-center min-w-[110px] h-16 m-3 mr-1 shadow-xl md:hover:scale-105 duration-200 md:hover:cursor-pointer p-2 rounded-lg`}
              to={`/level/${levelCode}`}
            >
              <div className="flex flex-col md:flex-row justify-evenly h-full">
                <h2 className="md:text-3xl my-auto">
                  {t("level")} {levelCode}
                </h2>
                <FaHandPointLeft className="my-auto md:mx-0 mx-auto" />
              </div>
            </Link>
          )}
        </div>
        <div className={`basis-4/5 h-full`}>
          <div className="h-32 shadow-xl bg-white text-emerald-500 font-semibold m-2 rounded-lg flex flex-col justify-start relative">
            <div
              className="overflow-y-auto always-show-scrollbar"
              ref={contentRef}
            >
              {categories?.map((category, index) => (
                <div key={index}>
                  <Link to={`/category/${category.categoryCode}`}>
                    <div className="flex hover:text-emerald-600 flex-row justify-between px-3 my-2">
                      <p className="text-xs md:text-base">
                        {language === "en"
                          ? category.categoryNameEn
                          : category.categoryNameHr}
                      </p>
                      <div className="flex flex-row gap-3 my-auto">
                        {category.newCategory && (
                          <NewBadge className="max-w-12" />
                        )}
                        <FaArrowRight className="my-auto" />
                      </div>
                    </div>
                  </Link>
                  <hr className="mx-3 mt-2 bg-black" />
                </div>
              ))}
              {!categories && <p className="mt-2">{t("noCategoriesAdded")}!</p>}
            </div>
            {showScrollIndicator && (
              <div className="absolute md:hidden bottom-0 left-2/3 transform -translate-x-1/2 animate-bounce">
                <FaArrowDown className="text-emerald-500" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
