import React, { useContext, useEffect, useState } from "react";
import { HomeSelector } from "../components/HomeSelector/HomeSelector";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/config/firebase";
import { CardLevel, CardLevelProps } from "../components/CardLevel/CardLevel";
import { FaArrowUp } from "react-icons/fa";
import { LanguageContext } from "../utils/LanguageProvider";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import { SpecialMessage } from "../components/SpecialMessage/SpecialMessage";
import { WelcomeComponent } from "../components/WelcomeComponent/WelcomeComponent";
import { SpecialMessageLevels } from "../components/SpecialMessage/SpecialMessageLevels";
import { Loader } from "../components/Loader/Loader";
import { useGetActiveSubscription, useGetCustomer } from "../services";

export const HomePage: React.FC = () => {
  const [levels, setLevels] = useState<CardLevelProps[]>([]);
  const [showScrollTop, setShowScrollTop] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useContext(LanguageContext);
  const { userDetails, updateUserDetails } = useContext(UserDetailsContext);
  const [filteredLevels, setFilteredLevels] = useState<CardLevelProps[]>([]);

  const { data: activeSubscription, refetch } = useGetActiveSubscription({
    customerId: userDetails?.uid,
  });

  useEffect(() => {
    if (!userDetails) updateUserDetails();
  }, []);

  const updateLevel = async () => {
    try {
      const customerId = uniqueCustomer.id;
      const result = await fetch(
        "https://us-central1-survstash-f265b.cloudfunctions.net/updateUserLevelManually",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: customerId,
          }),
        }
      );
      const responseData = await result.json();
      console.log("Cloud Function Response:", responseData);
    } catch (error) {
      console.error("Error calling Cloud Function:", error);
    }
  };

  const { data: uniqueCustomer } = useGetCustomer({
    customerId: userDetails?.uid,
  });

  useEffect(() => {
    if (uniqueCustomer?.id) {
      const fetchData = async () => {
        console.log("test update");
        await updateLevel();
      };

      fetchData();
    }
  }, [uniqueCustomer?.id, userDetails?.level]);

  useEffect(() => {
    const getLevelsData = async () => {
      setLoading(true);
      try {
        const levelsCollectionRef = collection(db, "levels");
        const querySnapshot = await getDocs(levelsCollectionRef);

        if (userDetails && userDetails?.level !== undefined) {
          const levelsData: CardLevelProps[] = querySnapshot.docs
            .map((doc) => doc.data() as CardLevelProps)
            .sort((a, b) => Number(a.levelCode) - Number(b.levelCode))
            .slice(userDetails.level, userDetails.level + 12);

          setLevels(levelsData);
        } else {
          setError("User details are not available.");
        }
      } catch (error) {
        setError(
          `Error fetching levels data: ${
            error instanceof Error ? error.message : "Unknown error"
          }`
        );
      } finally {
        setLoading(false);
      }
    };

    if (userDetails) {
      getLevelsData();
    }

    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [userDetails, userDetails?.level]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  console.log(uniqueCustomer);

  const isNotSubscribed = activeSubscription?.role;

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const minLevel = user?.level || 0;

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const minLevel = user?.level || 0;

    const updatedFilteredLevels = levels.filter(
      (level) => parseInt(level.levelCode) >= minLevel
    );

    setFilteredLevels(updatedFilteredLevels);
  }, [levels, userDetails]);

  return (
    <div className="max-w-[900px] min-h-[calc(100vh-180px)] container mx-auto">
      <h1 className="text-xl font-bold pt-8 uppercase">
        {t("homeScreenTitle")}
      </h1>
      <hr className="mb-4 md:mb-10 h-0.5 mx-3 mt-2 bg-gray-400" />
      {error && <p className="text-red-500">{error}</p>}
      {!isNotSubscribed && <WelcomeComponent />}
      {uniqueCustomer?.customerCustom9 && (
        <div className="px-4">
          <SpecialMessage message={uniqueCustomer?.customerCustom9} />
        </div>
      )}
      <HomeSelector />
      <div className="flex w-full flex-col mx-auto my-4 flex-wrap justify-evenly">
        {loading ? (
          <Loader />
        ) : (
          filteredLevels.map((card, index) => (
            <CardLevel
              key={index}
              index={index}
              levelCode={card.levelCode}
              categories={card.categories}
              levelCustom1={card.levelCustom1}
              levelCustom2={card.levelCustom2}
              levelDescriptionEn={card.levelDescriptionEn}
              levelDescriptionHr={card.levelDescriptionHr}
              levelNameEn={card.levelNameEn}
              levelNameHr={card.levelNameHr}
              levelPicture={card.levelPicture}
            />
          ))
        )}
      </div>
      {!loading && (
        <div className="px-4">
          <SpecialMessageLevels />
        </div>
      )}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 bg-emerald-500 opacity-60 transition-colors duration-100 text-white h-12 w-12 p-2 rounded-full shadow-lg hover:bg-emerald-600"
        >
          <FaArrowUp className="mx-auto" />
        </button>
      )}
    </div>
  );
};
