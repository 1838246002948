import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";

const getAllNews = async () => {
  try {
    const newsCollection = collection(db, "news");
    const newsQuery = query(newsCollection, orderBy("pinned", "desc"));
    const newsSnapshot = await getDocs(newsQuery);

    const news = newsSnapshot.docs.map((doc) => {
      const dataWithId = doc.data();
      return { id: doc.id, ...dataWithId } as any;
    });

    news.sort((a, b) => Number(b.pinned) - Number(a.pinned));

    return { status: true, message: "Success", news };
  } catch (error) {
    console.log("error", error);
    return { status: false, message: "Failed to get news", error };
  }
};

const getNewsByCodes = async (newsCodes) => {
  try {
    if (!newsCodes) {
      return getAllNews();
    }
    console.log("ggerg", newsCodes);

    const newsCollection = collection(db, "news");
    const newsQuery = query(
      newsCollection,
      where("newsCode", "in", newsCodes),
      orderBy("pinned", "desc")
    );
    const newsSnapshot = await getDocs(newsQuery);

    const news = newsSnapshot.docs.map((doc) => {
      const dataWithId = doc.data();
      return { id: doc.id, ...dataWithId } as any;
    });

    news.sort((a, b) => Number(b.pinned) - Number(a.pinned));

    console.log(
      "news: ",
      news?.map((news) => news?.pinned)
    );

    return { status: true, message: "Success", news };
  } catch (error) {
    console.log("error", error);
    return { status: false, message: "Failed to get news", error };
  }
};

const newsService = {
  getNewsByCodes,
};

export default newsService;
