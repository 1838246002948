import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDLvNwlg9Vi5cHGLsL-VvnQl4SI3klXCVc",
  authDomain: "survstash.com",
  projectId: "survstash-f265b",
  storageBucket: "survstash-f265b.appspot.com",
  messagingSenderId: "86209505785",
  appId: "1:86209505785:web:987e1c788797ac4326b528",
  measurementId: "G-4QJ5S6FHB1",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const facebookProvider = new FacebookAuthProvider();
const googleProvider = new GoogleAuthProvider();

export { app, auth, facebookProvider, googleProvider, db };
