import  { useState, useEffect, useContext, useMemo } from "react";
import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { LanguageContext } from "../utils/LanguageProvider";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import {
  db,
  useGetActiveSubscription,
  useGetAllItemsForUser,
  useGetCustomer,
} from "../services";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { IMail } from "../types";
import Modal from "../components/Modal/Modal";
import { Loader } from "../components/Loader/Loader";

export const DeliveryPage = () => {
  const { userDetails, updateUserDetails } = useContext(UserDetailsContext);
  const { t, language } = useContext(LanguageContext);
  const { data, isLoading, refetch } = useGetAllItemsForUser({
    userLevelCode: userDetails?.level,
  });
  const [refreshing, setRefreshing] = useState(false);

  const hasDeliveryItem = useMemo(
    () => data?.some((d) => d?.itemTypeEn === "Delivery"),
    [data]
  );

  const findDeliveryItem = (data) => {
    return data?.find((d) => d?.itemTypeEn === "Delivery") || null;
  };

  const { data: activeSubscription, refetch: refetchActiveSubscription } =
    useGetActiveSubscription({ customerId: userDetails?.uid });

       type AddressFormValues = {
  address: {
    city: string;
    street: string;
    postalCode: string;
  };
       };
  
  const initialValues: AddressFormValues = {
    address: {
      city: userDetails?.address?.city ?? "",
      street: userDetails?.address?.street ?? "",
      postalCode: userDetails?.address?.postalCode ?? "",
    },
  };

  

  const updateLevel = async () => {
    try {
      const result = await fetch(
        "https://us-central1-survstash-f265b.cloudfunctions.net/updateUserLevelManually",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: userDetails?.uid,
          }),
        }
      );
      const responseData = await result.json();
      console.log("Cloud Function Response:", responseData);
    } catch (error) {
      console.error("Error calling Cloud Function:", error);
    }
  };

  const itemsList = data?.map((item) => {
    return `<li><strong>Item Name:</strong> ${item.itemNameEn}, <strong>Item Code:</strong> ${item.itemCode} <strong>Item quantity:</strong> ${item.quantity}</li>
    `;
  });

  const { data: uniqueCustomer, refetch: customerRefetch } = useGetCustomer({
    customerId: userDetails?.uid,
  });

  

  const onRefresh = async () => {
    setRefreshing(true);
    updateUserDetails();
    await refetch();
    await customerRefetch();
    await refetchActiveSubscription();
    await updateLevel();
    setRefreshing(false);
  };

  const handleSubmit = async (
    values: AddressFormValues,
    { setSubmitting }: FormikHelpers<AddressFormValues>
  ) => {  console.log("bhhbbhhhv");
    setFormValues(values);
        setSubmitting(false);

  setDialogVisible(true);
  console.log("dialogVisible:", dialogVisible); // Check the value of dialogVisible
};

  const updateUserLevel = async () => {
    try {
      const userRef = doc(db, "users", userDetails?.uid);
      await updateDoc(userRef, {
        level: 0,
      });
      console.log("User level updated successfully to 0");
      return true;
    } catch (error) {
      console.error("Error updating user level:", error);
      return false;
    }
  };

  const updateCustomerDeliveryDate = async () => {
    try {
      const deliveryDateSeconds = Math.floor(Date.now() / 1000);
      Math.floor(Date.now() / 1000);
      const customerRef = doc(db, "customers", userDetails?.uid);
      await setDoc(
        customerRef,
        { deliveryDate: deliveryDateSeconds },
        { merge: true }
      );
      return true;
    } catch (error) {
      console.error("Error updating delivery date for customer:", error);
      return false;
    }
  };

  const createMail = async (mail: IMail) => {
    try {
      const mailCollection = collection(db, "mail");
      const newMailRef = await addDoc(mailCollection, mail);

      const mailSnapshot = await getDoc(newMailRef);
      const newMailData = mailSnapshot.data();

      return {
        status: true,
        mailId: newMailRef.id,
        newMailData: newMailData,
      };
    } catch (error) {
      console.log("Error", error);
      return { status: false, message: "Failed to create mail", error };
    }
  };

  const updateUserInFirestore = async (userId, newData) => {
    try {
      const userRef = doc(db, "users", userId);
      const customerRef = doc(db, "customers", userId);

      await setDoc(userRef, newData, { merge: true });
      await setDoc(customerRef, { customerCustom1: "0" }, { merge: true });

      console.log("User data updated successfully!");
      return true;
    } catch (error) {
      console.error("Error updating user data:", error);
      return false;
    }
  };

  const handleConfirm = async () => {
    const htmlBody = `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; }
            .container { max-width: 600px; margin: auto; padding: 20px; }
            .details { margin-bottom: 20px; }
            .details h2 { margin-bottom: 10px; color: #333; }
            .details ul { list-style-type: none; padding: 0; }
            .details li { margin-bottom: 8px; }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>New SurvStash Delivery Request!</h1>
            <div class="details">
              <h2>User Details:</h2>
              <ul>
                <li><strong>Name:</strong> ${userDetails?.name}</li>
                <li><strong>Last name:</strong> ${userDetails?.lastName}</li>
                <li><strong>Email:</strong> ${userDetails?.email}</li>
                <li><strong>Phone Number:</strong> ${
                  userDetails?.phoneNumber ||
                  "User did not enter their phone number yet."
                }</li>
                <li><strong>Delivery address:</strong></li>
                <li><strong>City:</strong> ${formValues?.address?.city}</li>
                <li><strong>Street:</strong> ${formValues?.address?.street}</li>
                <li><strong>Postal code:</strong> ${
                  formValues?.address?.postalCode
                }</li>
              </ul>
            </div>
            <div class="items">
              <h2>Items:</h2>
              <ul>
                ${itemsList.join("")}
              </ul>
            </div>
            <p>This email was sent to you because you are registered as the admin of the SurvStash app.</p>
          </div>
        </body>
      </html>
    `;

    try {
      const isLevelUpdated = await updateUserLevel();
      const isDeliveryDateUpdated =
        isLevelUpdated && (await updateCustomerDeliveryDate());
      const isEmailSentSuccessfully = await createMail({
        to: "survstash@gmail.com",
        message: {
          subject: "New delivery request!",
          text: "Please deliver the requested items when possible.",
          html: htmlBody,
        },
      });

      setDialogVisible(false);

      if (isEmailSentSuccessfully && isLevelUpdated && isDeliveryDateUpdated) {
        alert("Delivery with your items successfully requested!");
      } else {
        alert(
          "Something went wrong with your delivery, please try again later."
        );
      }

      await updateUserInFirestore(userDetails?.uid, { ...formValues });
      updateUserDetails();
    } catch (error) {
      console.error("Error with delivery:", error);
    }
  };

  const [dialogVisible, setDialogVisible] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [deliveryTitle, setDeliveryTitle] = useState("");
  const [deliveryDescription, setDeliveryDescription] = useState("");

  const validationSchema = Yup.object().shape({
    address: Yup.object().shape({
      city: Yup.string().required(t("cityIsRequired")),
      street: Yup.string().required(t("streetIsRequired")),
      postalCode: Yup.string().required(t("postalCodeIsRequired")),
    }),
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const deliveryItem = data.find((d) => d.itemTypeEn === "Delivery");
      setDeliveryTitle(
        language === "hr"
          ? deliveryItem.categoryNameHr || "Još uvijek nemate dostavu."
          : deliveryItem.categoryNameEn || "You do not have delivery yet."
      );
      setDeliveryDescription(
        language === "hr"
          ? deliveryItem.itemDescriptionHr || "Nema opisa dostave."
          : deliveryItem.itemDescriptionEn ||
              "There is no delivery description."
      );
    } else {
      setDeliveryTitle(
        language === "hr"
          ? "Još uvijek nemate dostavu."
          : "You do not have delivery yet."
      );
      setDeliveryDescription(
        language === "hr"
          ? "Nema opisa dostave."
          : "There is no delivery description."
      );
    }
  }, [data, language]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }

    function calculateNextAvailableDate(
    activeSubscriptionRole,
    activeSubscriptionCreated
  ) {
    const baseDate = activeSubscriptionCreated
      ? new Date(activeSubscriptionCreated.seconds * 1000)
      : new Date();

    let monthsToAdd = 1;

    switch (activeSubscriptionRole) {
      case "Basic":
        monthsToAdd = 2;
        break;
      case "Advanced":
        monthsToAdd = 1;
        break;
      case "Expert":
        monthsToAdd = 1;
        break;
      default:
        monthsToAdd = 1;
        break;
    }

    const nextDate = new Date(baseDate.getTime());
    nextDate.setUTCMonth(nextDate.getUTCMonth() + monthsToAdd);

    const availableDate = nextDate
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .join(".");

    return availableDate;
  }

  return (
    <div className="py-8 flex flex-col h-full max-w-[900px] mx-5 md:mx-auto">
      <div className="flex-1 overflow-auto">
        <div className="flex flex-col md:flex-row gap-5 justify-between">
          <div className="bg-white basis-1/2 rounded-lg mb-4 p-5 shadow">
            <div className="flex flex-row justify-center md:justify-start gap-5 items-center">
              <img
                className="w-20 h-20 my-auto rounded-lg"
                src={require("../assets/deliver.png")}
                alt="Delivery"
              />
              <div className="text-center">
                <h2 className="font-bold text-2xl text-primary">
                  {t("deliveryTitle")}
                </h2>
                <div>
                  <div className="flex justify-center items-center mb-2">
                    <span>{t("currentLevel")}: </span>
                    <span className="text-primary font-bold ml-1">
                      {userDetails?.level}
                    </span>
                  </div>
                  {userDetails.customClaimRole !== "Not subscribed" && (
                    <div className="flex justify-center items-center">
                      <span>{t("nextLevel")} </span>
                      <span className="font-bold text-primary ml-1">
                        {calculateNextAvailableDate(
                          activeSubscription?.role,
                          activeSubscription?.current_period_start
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white basis-1/2 py-4 px-10 mb-3 text-left rounded-lg border-2 border-yellow-200">
            <div className="flex items-center gap-2 mb-2">
              <button className="bg-yellow-200 rounded-lg h-10 w-10 p-2">
                i
              </button>
              <span className="font-semibold text-lg">{t("important")}</span>
            </div>
            <p>{t("deliveryWarning")}</p>
          </div>
        </div>

        {uniqueCustomer?.customerCustom8 && (
          <div className="bg-white p-4 mb-3 rounded-lg border-2 border-yellow-200">
            <span>{uniqueCustomer?.customerCustom8}</span>
          </div>
        )}

        <div style={{ marginTop: 5, marginBottom: 0 }}>
          <input
            type="text"
            value={deliveryTitle}
            disabled
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              marginTop: "5px",
              backgroundColor: "white"
            }}
          />
        </div>
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <textarea
            value={deliveryDescription}
            disabled
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              marginTop: "5px",
              resize: "none",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
              backgroundColor: "white"
            }}
            rows={5}
          />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize>
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <div>
              <label
                className="text-[#11B981] font-bold text-left"
                style={{ margin: "10px 0" }}>
                {t("deliveryAddressField")}
              </label>

              <Form className="space-y-4 mt-5">
                {/* City Field */}
                <Field
                  name="address.city"
                  as="input"
                  className="w-full p-2 border rounded-lg"
                  placeholder={
                    language === "hr" ? "Unesite grad" : "Enter city"
                  }
                />
                {touched.address?.city && errors.address?.city && (
                  <p className="text-red-500 text-xs">
                    {t(errors.address.city)}
                  </p>
                )}

                {/* Street Field */}
                <Field
                  name="address.street"
                  as="input"
                  className="w-full p-2 border rounded-lg"
                  placeholder={
                    language === "hr" ? "Unesite ulicu" : "Enter street"
                  }
                />
                {touched.address?.street && errors.address?.street && (
                  <p className="text-red-500 text-xs">
                    {t(errors.address.street)}
                  </p>
                )}

                {/* Postal Code Field */}
                <Field
                  name="address.postalCode"
                  as="input"
                  className="w-full p-2 border rounded-lg"
                  placeholder={
                    language === "hr"
                      ? "Unesite poštanski broj"
                      : "Enter postal code"
                  }
                />
                {touched.address?.postalCode && errors.address?.postalCode && (
                  <p className="text-red-500 text-xs">
                    {t(errors.address.postalCode)}
                  </p>
                )}

                {/* Submit Button */}
                <button
                  type="submit"
                  className={`w-full p-2 rounded-lg text-white font-bold text-center ${
                    !hasDeliveryItem ? "bg-gray-400" : "bg-[#11B981]"
                  }`}
                  disabled={!hasDeliveryItem}>
                  {t("deliver")}
                </button>
              </Form>
            </div>
          )}
        </Formik>

        <Modal isOpen={dialogVisible} onClose={() => setDialogVisible(false)}>
          <div className="p-5 text-center">
            <h2 className="font-bold text-lg">
              {language === "hr" ? "Potvrdite dostavu" : "Confirm Delivery"}
            </h2>
            <p className="my-3">
              {language === "hr"
                ? "Jeste li sigurni da želite izvršiti dostavu? Ukoliko pokrenete dostavu vaš nivo će biti stavljen na 0."
                : "Are you sure you want to deliver items? Choosing to deliver items will result in LEVEL going back to 0."}
            </p>
            <div className="flex justify-center gap-4">
              <button
                onClick={handleConfirm}
                className="bg-[#11B981] text-white px-4 py-2 rounded">
                {language === "hr" ? "Potvrdite" : "Confirm"}
              </button>
              <button
                onClick={() => setDialogVisible(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded">
                {language === "hr" ? "Odustani" : "Cancel"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
