import { collection, getDocs } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { db } from "../services";
import DOMPurify from 'dompurify';
import { FaArrowLeft, FaArrowUp } from "react-icons/fa";
import { LanguageContext } from "../utils/LanguageProvider";
import { BackButton } from "../components/BackButton/BackButton";
import { useNavigate } from "react-router-dom";

interface PrivacyData {
    description: string,
    description_en: string,
    type: string
}

export const PrivacyPage: React.FC = () => {
    const [privacy, setPrivacy] = useState<PrivacyData>();
    const [showScrollTop, setShowScrollTop] = useState<boolean>(false);
    const { language } = useContext(LanguageContext);
    const navigate = useNavigate();

    const getTermsData = async () => {
        try {
        const subscriptionsCollectionRef = collection(db, "privacyData");
        const querySnapshot = await getDocs(subscriptionsCollectionRef);

        const privacyData: PrivacyData[] = querySnapshot.docs.map((doc) => doc.data() as PrivacyData);
        const filteredData = privacyData.filter((terms) => terms.type === "privacy")
        setPrivacy(filteredData[0]);

        } catch (error) {
        console.error("Error fetching terms data:", error);
        }
    };

    useEffect(() => {
        getTermsData();

        const handleScroll = () => {
            if (window.scrollY > 300) {
              setShowScrollTop(true);
            } else {
              setShowScrollTop(false);
            }
          };
      
          window.addEventListener("scroll", handleScroll);
      
          return () => {
            window.removeEventListener("scroll", handleScroll);
          };

      }, []);

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
    

    const sanitizedHTML = DOMPurify.sanitize(language === "en" ? privacy?.description_en : privacy?.description);

    return(
        <div className="md:w-[900px] md:mx-auto px-6 my-10">
            <div onClick={() => navigate(-1)} className="flex pb-5 justify-start">
              <BackButton className="w-10 h-10">
                <FaArrowLeft className="mx-auto text-white"/>
              </BackButton>
            </div>
            <div className="prose text-left" dangerouslySetInnerHTML={{__html: sanitizedHTML}}></div>
            {showScrollTop && (
                <button
                onClick={scrollToTop}
                className="fixed bottom-4 right-4 bg-emerald-500 opacity-60 transition-colors duration-100 text-white h-12 w-12 p-2 rounded-full shadow-lg hover:bg-emerald-600"
                >
                <FaArrowUp className="mx-auto" />
                </button>
            )}
        </div>
    )
}