import React, { useContext } from "react";
import { Button } from "../Button/Button";
import { LanguageContext } from "../../utils/LanguageProvider";
import { Link } from "react-router-dom";
import { UserDetailsContext } from "../../utils/UserDetailsProvider";
import { useGetActiveSubscription } from "../../services";

export const ProfilePayments: React.FC = () => {
    const { t } = useContext(LanguageContext);
    const { userDetails } = useContext(UserDetailsContext);

    console.log("hahaha", userDetails);

    const {
        data: activeSubscription,
        isLoading: subscriptionFetchingLoading,
        isError: activeSubscriptionIsError,
        error: activeSubscriptionError,
        refetch,
      } = useGetActiveSubscription({ customerId: userDetails?.uid });
      
    const isNotSubscribed = !activeSubscription?.role;

    return(
        <div className="my-3 flex flex-col gap-3">
            <Link to="/one-time-payments">
                <Button disabled={isNotSubscribed} className={`text-white ${isNotSubscribed && "bg-gray-400 text-gray-500"} h-16 bg-emerald-600 ${!isNotSubscribed && "hover:bg-emerald-700"}`}>{t("oneTimePayment")}</Button>
            </Link>
            {isNotSubscribed ? (
                    <Link to="/subscription">
                        <Button className="text-white h-16 bg-emerald-600 hover:bg-emerald-700">
                            {t("changeSubscription")}
                        </Button>
                    </Link>
                ) : (
                    <a target="_blank" href="https://billing.stripe.com/p/login/9AQcQk3Lm3Rn4Te288" rel="noreferrer">
                        <Button className="text-white h-16 bg-emerald-600 hover:bg-emerald-700">
                            {t("changeSubscription")}
                        </Button>
                    </a>
                )}
            <Link to="/payment-log">
                <Button className="text-white bg-emerald-600 h-16 hover:bg-emerald-700">{t("paymentLog")}</Button>
            </Link>
        </div>
    )
}