import React from "react";

interface SpecialMessageProps {
  message: string;
}

export const SpecialMessage: React.FC<SpecialMessageProps> = ({ message }) => {
  return (
    <div className="md:mx-auto my-4 rounded-xl bg-white border-2 border-yellow-300 shadow-xl p-5">
      <p className="font-semibold">{message}</p>
    </div>
  );
};
