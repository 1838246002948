import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import customersService from "../api/customers";

interface GetCustomerProps {
  customerId?: string;
  options?: UseQueryOptions<any, Error>;
}

export function useGetCustomer({ customerId, options }: GetCustomerProps) {
  return useQuery<any, Error>(
    ["getCustomer", customerId],
    async () => {
      const { status, customer, message } =
        await customersService.getCustomerWithPayments(customerId);

      if (status) {
        return customer;
      } else {
        throw new Error(message);
      }
    },
    options
  );
}
