import React, { useContext } from 'react';
import { AnchorLink } from '../AnchorLink/AnchorLink';
import { LanguageContext } from '../../utils/LanguageProvider';

interface FooterProps {
  companyName: string;
  year: number;
  privacyLink?: string;
  termsLink?: string;
  faqLink?: string;
  customerServiceLink?: string;
  isAuthenticated: boolean;
}

export const Footer: React.FC<FooterProps> = ({
  companyName,
  year,
  privacyLink,
  termsLink,
  faqLink,
  customerServiceLink,
  isAuthenticated,
}) => {
  const { t } = useContext(LanguageContext);

  return (
    <footer
      className={`bg-emerald-500 min-h-[90px] ${
        isAuthenticated ? "mb-[65px]" : "mb-0" // Conditionally apply margin
      } md:mb-0 text-gray-50 py-4 border-t border-emerald-600 text-center md:w-full`}>
      <p>
        &copy; {year} {companyName}. {t("allRightsReserved")}.
      </p>
      <div className="mt-2 px-2">
        {privacyLink && (
          <AnchorLink href={privacyLink}>{t("dataPrivacy")}</AnchorLink>
        )}
        {termsLink && (
          <>
            {privacyLink && <span className="mx-2 px-2">|</span>}
            <AnchorLink href={termsLink}>{t("termsAndConditions")}</AnchorLink>
          </>
        )}
        {faqLink && (
          <>
            {termsLink && <span className="mx-2 px-2">|</span>}
            <AnchorLink href={faqLink}>{t("faq")}</AnchorLink>
          </>
        )}
        {customerServiceLink && (
          <>
            {faqLink && <span className="mx-2 px-2">|</span>}
            <AnchorLink href={customerServiceLink}>
              {t("customerService")}
            </AnchorLink>
          </>
        )}
      </div>
    </footer>
  );
};
