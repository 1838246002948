import React, { useContext, useState, useEffect } from "react";
import { Input } from "../components/Input/Input";
import { Button } from "../components/Button/Button";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import { LanguageContext } from "../utils/LanguageProvider";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../services/config/firebase";
import { countries } from "../types/Countries/Countries";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BackButton } from "../components/BackButton/BackButton";
import { SpecialMessage } from "../components/SpecialMessage/SpecialMessage";
import { useGetActiveSubscription, useGetCustomer } from "../services";

export const PersonalDataPage: React.FC = () => {
  const userContext = useContext(UserDetailsContext);
  const { t } = useContext(LanguageContext);
  const { userDetails, updateUserDetails } = userContext;

  const [formData, setFormData] = useState({
    email: "",
    countryCode: "",
    regularPartOfPhoneNumber: "",
    address: {
      city: "",
      street: "",
      postalCode: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (userDetails?.uid) {
        try {
          const userDocRef = doc(db, "users", userDetails.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const data = userDoc.data();
            setFormData({
              email: data.email || "",
              countryCode: data.callingCode || "",
              regularPartOfPhoneNumber: data.regularPartOfPhoneNumber || "",
              address: {
                city: data.address?.city || "",
                street: data.address?.street || "",
                postalCode: data.address?.postalCode || "",
              },
            });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [userDetails?.uid]);

  const { data: uniqueCustomer } = useGetCustomer({
    customerId: userDetails?.uid,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "city" || name === "street" || name === "postalCode") {
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [name]: value,
        },
      }));
    } else if (name === "countryCode") {
      setFormData((prevData) => ({
        ...prevData,
        countryCode: value,
      }));
    } else if (name === "phoneNumber") {
      setFormData((prevData) => ({
        ...prevData,
        regularPartOfPhoneNumber: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const combinePhoneNumber = (
    callingCode: string,
    otherPartOfNumber: string
  ) => {
    return `${callingCode} ${otherPartOfNumber}`;
  };

  const { data: activeSubscription, refetch } = useGetActiveSubscription({
    customerId: userDetails?.uid,
  });

  const formatPhoneNumber = (number: string): string => {
    const cleanedNumber = number.replace(/\D/g, "");

    const formattedNumber = cleanedNumber.replace(/(\d{3})(?=\d)/g, "$1 ");

    return formattedNumber;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (!userDetails?.uid) {
      setLoading(false);
      return;
    }

    try {
      const userDocRef = doc(db, "users", userDetails.uid);

      await updateDoc(userDocRef, {
        email: formData.email,
        phoneNumber: combinePhoneNumber(
          formData.countryCode,
          formatPhoneNumber(formData.regularPartOfPhoneNumber)
        ),
        regularPartOfPhoneNumber: formatPhoneNumber(
          formData.regularPartOfPhoneNumber
        ),
        address: formData.address,
        callingCode: formData.countryCode,
      });

      const updatedUserDetails = {
        ...userDetails,
        email: formData.email,
        phoneNumber: combinePhoneNumber(
          formData.countryCode,
          formData.regularPartOfPhoneNumber
        ),
        regularPartOfPhoneNumber: formData.regularPartOfPhoneNumber,
        address: formData.address,
        callingCode: formData.countryCode,
      };

      localStorage.setItem("user", JSON.stringify(updatedUserDetails));
      await updateUserDetails(updatedUserDetails);

      setShowModal(true);
    } catch (error) {
      console.error("Error updating user data:", error);
      alert(t("dataSaveFailed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto max-w-[600px] px-5 min-h-[calc(100vh-180px)] flex flex-col mt-5 mb-8">
      <div className="flex justify-start">
        <Link to="/profile">
          <BackButton className="w-10 h-10">
            <FaArrowLeft className="mx-auto text-white" />
          </BackButton>
        </Link>
      </div>
      <h1 className="text-xl font-bold uppercase">{t("personalData")}</h1>
      <hr className="mb-4 md:mb-2 h-0.5 mt-2 bg-gray-400" />
      {Boolean(
        uniqueCustomer?.customerCustom4 ||
          uniqueCustomer?.customerCustom4 ||
          uniqueCustomer?.customerCustom6
      ) && (
        <div>
          {uniqueCustomer?.customerCustom4 && (
            <SpecialMessage message={uniqueCustomer?.customerCustom4} />
          )}
          {uniqueCustomer?.customerCustom5 && (
            <SpecialMessage message={uniqueCustomer?.customerCustom5} />
          )}
          {uniqueCustomer?.customerCustom6 && (
            <SpecialMessage message={uniqueCustomer?.customerCustom6} />
          )}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <Input
            type="text"
            name="email"
            placeholder={t("email")}
            value={formData.email}
            disabled
            onChange={handleChange}
          />
          <div className="flex items-center mt-2">
            <select
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              className="w-1/4 mr-2 h-[48px] min-w-28 rounded-lg p-2 bg-white shadow-xl text-gray-700 focus:outline-none"
            >
              <option value="" disabled>
                {t("selectCountryCode")}
              </option>
              {countries.map(({ callingCode, cca2 }, index) => (
                <option key={index} value={callingCode}>
                  {cca2} ({callingCode})
                </option>
              ))}
            </select>
            <Input
              type="text"
              name="phoneNumber"
              placeholder={t("phoneNumber")}
              value={formData.regularPartOfPhoneNumber}
              onChange={handleChange}
              className="w-3/4"
            />
          </div>
        </div>
        <h2 className="text-center text-lg font-semibold text-emerald-600 mb-2 mt-6">
          {t("deliveryAddressField")}
        </h2>
        <div className="text-left">
          <label className="text-emerald-600">{t("city")}</label>
          <Input
            type="text"
            name="city"
            placeholder={t("city")}
            value={formData.address.city}
            onChange={handleChange}
          />
          <label className="text-emerald-600">{t("street")}</label>
          <Input
            type="text"
            name="street"
            placeholder={t("street")}
            value={formData.address.street}
            onChange={handleChange}
          />
          <label className="text-emerald-600">{t("postalCode")}</label>
          <Input
            name="postalCode"
            type="number"
            pattern="^[0-9-]*$"
            placeholder={t("postalCode")}
            value={formData.address.postalCode}
            onChange={handleChange}
          />
        </div>
        <Button
          className="rounded-lg mt-5 bg-emerald-500 text-white w-full hover:bg-emerald-600"
          type="submit"
          disabled={loading}
        >
          {loading ? t("saving") : t("save")}
        </Button>
      </form>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-8">
            <h2 className="text-lg font-semibold mb-4">
              {t("dataSavedSuccessfully")}
            </h2>
            <Button
              className="rounded-lg bg-emerald-500 text-white w-full hover:bg-emerald-600"
              onClick={() => setShowModal(false)}
            >
              OK
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
