import React, { useContext, useEffect, useState } from "react";
import { CardLevelProps } from "../components/CardLevel/CardLevel";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services";
import { Link, useParams } from "react-router-dom";
import { CategoryItem } from "../components/CategoryComponent/CategoryItem";
import { LanguageContext } from "../utils/LanguageProvider";
import { FaArrowLeft } from "react-icons/fa";
import { BackButton } from "../components/BackButton/BackButton";
import { Loader } from "../components/Loader/Loader";

export const LevelPage: React.FC = () => {
    const [level, setLevel] = useState<CardLevelProps | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { levelCode } = useParams<{ levelCode: string }>();
    const { language, t } = useContext(LanguageContext);

    useEffect(() => {
        const getLevelDataByCode = async (levelCode: string) => {
            try {
                const levelsCollectionRef = collection(db, "levels");

                const querySnapshot = await getDocs(levelsCollectionRef);

                const levelsData: CardLevelProps[] = querySnapshot.docs
                    .map((doc) => doc.data() as CardLevelProps);

                levelsData.find((level) => {
                    if (String(level.levelCode) === levelCode) {
                        console.log(level);
                        setLevel(level);
                    }
                });
            } catch (error) {
                console.error("Error fetching level data:", error);
                setError("Failed to fetch level data");
                setLevel(null);
            } finally {
                setLoading(false);
            }
        };

        if (levelCode) {
            getLevelDataByCode(levelCode);
        }
    }, [levelCode]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="max-w-[900px] mx-auto container min-h-[calc(100vh-230px)] text-left md:text-center my-4 px-6">
            <div className="flex justify-start">
                <Link to="/">
                    <BackButton className="w-10 h-10">
                        <FaArrowLeft className="mx-auto text-white" />
                    </BackButton>
                </Link>
            </div>
            {level ? (
                <div>
                    <li className="text-yellow-300 font-medium">{t("Code")}: {level.levelCode}</li>
                    <h1 className="text-emerald-500 text-xl my-2 font-bold">{language === "en" ? level.levelNameEn : level.levelNameHr}</h1>
                    {level.levelPicture && <img className="my-4 rounded-xl mx-auto" src={level.levelPicture} alt={language === "en" ? level.levelNameEn : level.levelNameHr} />}
                    <p className="text-justify font-semibold whitespace-pre-line">{language === "en" ? level.levelDescriptionEn : level.levelDescriptionHr}</p>
                    <div className="my-8 flex flex-col gap-4">
                        <CategoryItem name={level.levelCustom1} />
                        <CategoryItem name={level.levelCustom2} />
                    </div>
                </div>
            ) : (
                <p>{t("noLevelFound")}: {levelCode}</p>
            )}
        </div>
    );
};