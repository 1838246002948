import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Article } from "./NewsPage";
import { BackButton } from "../components/BackButton/BackButton";
import { FaArrowLeft } from "react-icons/fa";
import { LanguageContext } from "../utils/LanguageProvider";
import { Loader } from "../components/Loader/Loader";

export const ArticlePage: React.FC = () => {
  const { newsCode } = useParams<{ newsCode: string }>();
  const [article, setArticle] = useState<Article | null>(null);
  const db = getFirestore();
  const { language } = useContext(LanguageContext);

  const getArticle = async () => {
    try {
      const newsCollectionRef = collection(db, "news");
      const querySnapshot = await getDocs(newsCollectionRef);
      const newsData: Article[] = querySnapshot.docs.map((doc) => doc.data() as Article);
      newsData.forEach((article) => {
        if (article.newsCode === newsCode) {
          setArticle(article);
        }
      });
    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };

  useEffect(() => {
    getArticle();
  }, []);

  if (!article) {
    return <Loader />;
  }

  const newsTitle = language === "hr" ? article.newsTitleHr : article.newsTitleEn;
  const newsDescription = language === "hr" ? article.newsDescriptionHr : article.newsDescriptionEn;
  const newsCategoryTitle = language === "hr" ? article.category.newsCategoryTitleHr : article.category.newsCategoryTitleEn;

  return (
    <div className="max-w-[900px] md:mx-auto px-5 mb-6 mt-4 mx-5 text-left">
      <Link to="/news">
        <BackButton className="w-10 h-10">
          <FaArrowLeft className="mx-auto text-white" />
        </BackButton>
      </Link>
      <li className="text-yellow-300 text-lg my-1 font-semibold">{newsCategoryTitle}</li>
      <p className="font-normal">{new Date(article.newsDate * 1000).toLocaleDateString()}</p>
      <h1 className="text-xl font-bold my-2 text-emerald-500">{newsTitle}</h1>
      <img src={article.newsPicture} alt={newsTitle} className="w-full rounded-xl h-auto my-4" />
      <p className="whitespace-pre-line font-medium text-justify">{newsDescription}</p>
    </div>
  );
};
