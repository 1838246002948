import React, { useContext, useEffect, useState } from "react";
import { Button } from "../components/Button/Button";
import { LanguageContext } from "../utils/LanguageProvider";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/config/firebase";
import {
  CardLevelProps,
  CategoryProps,
  ItemProps,
} from "../components/CardLevel/CardLevel";
import { SpecialMessage } from "../components/SpecialMessage/SpecialMessage";
import { NewBadge } from "../components/NewBadge/NewBadge";
import { useGetActiveSubscription, useGetCustomer } from "../services";
import { calculateAvailableDate } from "../utils/dateUtils";
import { Loader } from "../components/Loader/Loader";

export interface ExtendedItemProps extends ItemProps {
  categoryNameEn: string;
  categoryNameHr: string;
}

export const MyStashPage: React.FC = () => {
  const { t, language } = useContext(LanguageContext);
  const { userDetails, updateUserDetails } = useContext(UserDetailsContext);
  const [currentSubscription, setCurrentSubscription] = useState<any | null>(
    null
  );
  const [allItems, setAllItems] = useState<ExtendedItemProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { data: activeSubscription, refetch } = useGetActiveSubscription({
    customerId: userDetails?.uid,
  });

  useEffect(() => {
    if (!userDetails) {
      updateUserDetails();
    }
  }, [userDetails, updateUserDetails]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      if (
        activeSubscription?.role &&
        activeSubscription.role !== "Not subscribed"
      ) {
        try {
          const subscriptionsCollectionRef = collection(db, "subscription");
          const querySnapshot = await getDocs(subscriptionsCollectionRef);

          const subscriptionsData: any[] = querySnapshot.docs.map(
            (doc) => doc.data() as any
          );

          const matchedSubscription = subscriptionsData.find(
            (sub) => sub.subscriptionCode === activeSubscription.role
          );
          setCurrentSubscription(matchedSubscription || null);
        } catch (error) {
          console.error("Error fetching subscription data:", error);
        }
      }
    };

    const fetchLevelData = async () => {
      setLoading(true);
      if (userDetails?.level) {
        try {
          const levelsCollectionRef = collection(db, "levels");
          const querySnapshot = await getDocs(levelsCollectionRef);

          const levelsData: CardLevelProps[] = querySnapshot.docs.map(
            (doc) => doc.data() as CardLevelProps
          );
          const matchedLevel = levelsData.find(
            (level) => Number(level.levelCode) === userDetails.level
          );

          if (matchedLevel) {
            const itemsArray = matchedLevel.categories.reduce(
              (acc: ExtendedItemProps[], category: CategoryProps) => {
                const categoryItemsWithNames = category.items.map((item) => ({
                  ...item,
                  categoryNameEn: category.categoryNameEn,
                  categoryNameHr: category.categoryNameHr,
                }));
                return acc.concat(categoryItemsWithNames);
              },
              []
            );
            setAllItems(itemsArray);
          }
        } catch (error) {
          console.error("Error fetching level data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
    fetchLevelData();
  }, [activeSubscription?.role, userDetails?.level]);

  const { data: uniqueCustomer } = useGetCustomer({
    customerId: userDetails?.uid,
  });

  if (!userDetails) {
    return <div>Loading...</div>;
  }

  if (loading) {
    return <Loader />;
  }

  const subscription = activeSubscription?.role || "";
  const level = userDetails?.level + 1;

  const timestamp = activeSubscription?.created.seconds;

  const getAvailableDate = (index: number) => {
    return calculateAvailableDate(
      subscription,
      activeSubscription?.current_period_start,
      index,
      userDetails
    );
  };

  const subscriptionText = !activeSubscription?.role
    ? "notSubbed"
    : activeSubscription?.role;
  const isNotSubscribed = !activeSubscription?.role;
  const subFreqName =
    language === "en"
      ? currentSubscription?.subscriptionFreqNameEn
      : currentSubscription?.subscriptionFreqNameHr;

  function calculateNextAvailableDate(
    activeSubscriptionRole: any,
    activeSubscriptionCreated: any
  ) {
    const baseDate = activeSubscriptionCreated
      ? new Date(activeSubscriptionCreated.seconds * 1000)
      : new Date();

    let monthsToAdd = 1;

    switch (activeSubscriptionRole) {
      case "Basic":
        monthsToAdd = 2;
        break;
      case "Advanced":
        monthsToAdd = 1;
        break;
      case "Expert":
        monthsToAdd = 1;
        break;
      default:
        monthsToAdd = 1;
        break;
    }

    const nextDate = new Date(baseDate.getTime());
    nextDate.setUTCMonth(nextDate.getUTCMonth() + monthsToAdd);

    const availableDate = nextDate
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .join(".");

    return availableDate;
  }

  return (
    <div className="container mx-auto px-4 max-w-[900px] min-h-[calc(100vh-180px)]">
      <h1 className="text-xl font-bold pt-8 uppercase">{t("myStash")}</h1>
      <hr className="mb-4 md:mb-10 h-0.5 mt-2 bg-gray-400" />
      {uniqueCustomer?.customerCustom10 && (
        <SpecialMessage message={uniqueCustomer?.customerCustom10} />
      )}
      <div className="flex flex-row gap-4">
        <div className="bg-emerald-500 flex flex-col px-2 py-4 justify-around rounded-lg gap-3 text-white basis-1/2">
          <h3 className="font-bold text-xl">
            {t("level")} {userDetails.level}
          </h3>
          {activeSubscription?.role && (
            <p className="font-medium w-24 mx-auto text-sm">
              {t("nextLevel")} { calculateNextAvailableDate(
                  activeSubscription?.role,
                  activeSubscription?.current_period_start
                )}
            </p>
          )}
        </div>
        <div className="bg-emerald-500 flex flex-col p-2 py-4 rounded-lg gap-3 text-white basis-1/2">
          <h3 className="font-bold text-xl">
            {isNotSubscribed
              ? t(subscriptionText)
              : t(subscriptionText) + " " + t("subscription")}
          </h3>
          <p className="font-medium text-sm">
            {isNotSubscribed
              ? t("noSubscription")
              : currentSubscription
              ? "(" + subFreqName + ")"
              : "Loading..."}
          </p>
        </div>
      </div>
      {isNotSubscribed ? (
        <>
          <div className="bg-white flex flex-col gap-3 rounded-xl my-5 p-3 text-emerald-600">
            <h4 className="font-medium">{t("noStashItems")}</h4>
            <p>{t("noStashItemsDescription")}</p>
          </div>
          <div className="flex flex-row justify-around gap-2">
            {isNotSubscribed ? (
              <Link className="w-full" to="/subscription">
                <Button className="rounded-3xl text-emerald-500 bg-white hover:bg-gray-300">
                  {t("changeSubscription")}
                </Button>
              </Link>
            ) : (
              <a
                className="w-full"
                target="_blank"
                href="https://billing.stripe.com/p/login/9AQcQk3Lm3Rn4Te288"
                rel="noreferrer"
              >
                <Button className="rounded-3xl text-emerald-500 bg-white hover:bg-gray-300">
                  {t("changeSubscription")}
                </Button>
              </a>
            )}
          </div>
        </>
      ) : (
        <div>
          {allItems.length > 0 && (
            <div>
              {allItems.map((item, index) => (
                <Link key={index} to={`/my-stash/${item.itemCode}`}>
                  <div className="rounded-lg cursor-pointer shadow-xl px-4 min-h-[150px] text-white font-medium bg-emerald-500 flex flex-row justify-between my-5 hover:bg-emerald-600">
                    <img
                      className="md:w-32 md:h-32 h-28 w-28 my-auto rounded-xl"
                      alt={item.itemNameEn}
                      src={`${item.itemPicture}`}
                    />
                    <div className="flex  flex-col justify-center gap-2">
                      {item.newItem === "New" ? (
                        <NewBadge className="w-20 h-6 content-center" />
                      ) : (
                        <p></p>
                      )}
                      <p className="md:text-2xl px-3">
                        {language === "en" ? item.itemNameEn : item.itemNameHr}
                      </p>
                      <p className="font-normal">
                        {language === "en"
                          ? item.categoryNameEn
                          : item.categoryNameHr}
                      </p>
                    </div>
                    <button className="bg-white text-gray-600 p-2 rounded-md mt-5 h-10 w-10">
                      {item.quantity}
                    </button>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
