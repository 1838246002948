import React from "react";

interface AnchorLinkProps {
    children: React.ReactNode,
    href: string,
    className?: string,
}

export const AnchorLink: React.FC<AnchorLinkProps> = ({ children, href, className}) => {
    return(
        <a href={href} className={`hover:text-gray-300 ${className}`}>{children}</a>
    )
}