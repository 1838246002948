import React, { useContext } from "react";
import { Button } from "../components/Button/Button";
import { LanguageContext } from "../utils/LanguageProvider";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { BackButton } from "../components/BackButton/BackButton";
import { collection, addDoc, getDoc } from "firebase/firestore";
import { db } from "../services";
import { IMail } from "../types";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import { Formik } from "formik";

export const CustomerServicePage: React.FC = () => {
  const { userDetails } = useContext(UserDetailsContext);
  const { t, language } = useContext(LanguageContext);
  const navigate = useNavigate();

  const createMail = async (mail: IMail) => {
    try {
      const mailCollection = collection(db, "mail");
      const newMailRef = await addDoc(mailCollection, mail);
      const mailSnapshot = await getDoc(newMailRef);
      const newMailData = mailSnapshot.data();
      return {
        status: true,
        mailId: newMailRef.id,
        newMailData: newMailData,
      };
    } catch (error) {
      console.error("Error saving mail to Firestore:", error);
      return { status: false, message: "Failed to create mail", error };
    }
  };

  const handleConfirm = async (values: any) => {
    const htmlBody = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
            }
            .container {
              max-width: 600px;
              margin: auto;
              padding: 20px;
            }
            .details {
              margin-bottom: 20px;
            }
            .details h2 {
              margin-bottom: 10px;
              color: #333;
            }
            .details ul {
              list-style-type: none;
              padding: 0;
            }
            .details li {
              margin-bottom: 8px;
            }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>New Customer Service SurvStash inquiry!</h1>
            <div class="details">
              <h2>User Details:</h2>
              <p>${values?.inquiry}</p>
              <ul>
                <li><strong>Name:</strong> ${userDetails?.name}</li>
                <li><strong>Last name:</strong> ${userDetails?.lastName}</li>
                <li><strong>Email:</strong> ${userDetails?.email}</li>
              </ul>
            </div>
            <p>This email was sent because you are registered as the admin of the SurvStash app.</p>
          </div>
        </body>
      </html>
    `;

    try {
      await createMail({
        to: "survstash@gmail.com",
        message: {
          subject: "New Customer Service Inquiry",
          text: "Please address this inquiry as soon as possible.",
          html: htmlBody,
        },
      });
      alert(
        language === "en"
          ? "Successfully sent inquiry!"
          : "Uspješno poslan upit!"
      );
      navigate("/profile");
    } catch (error) {
      console.error("Error sending email:", error);
      alert(
        language === "en"
          ? "Failed to send inquiry."
          : "Greška prilikom slanja upita."
      );
    }
  };

  return (
    <div className="container max-w-[900px] px-5 mt-5 pt-3 mx-auto min-h-[calc(100vh-200px)] flex flex-col gap-4">
      <div className="flex justify-start">
        <Link to="/profile">
          <BackButton className="w-10 h-10">
            <FaArrowLeft className="mx-auto text-white" />
          </BackButton>
        </Link>
      </div>
      <h1 className="text-xl font-bold">{t("sendUsEmail")}.</h1>

      <Formik
        initialValues={{ inquiry: "" }}
        onSubmit={(values) => handleConfirm(values)}
      >
        {({ handleChange, handleBlur, handleSubmit, values }) => (
          <>
            <textarea
              className="rounded-lg h-40 p-4 align-top border border-emerald-600"
              placeholder={t("writeYourInquiry")}
              value={values.inquiry}
              onChange={handleChange("inquiry")}
              onBlur={handleBlur("inquiry")}
            />
            <Button
              type="submit"
              onClick={handleSubmit}
              className="rounded-lg bg-emerald-500 hover:bg-emerald-600 text-white"
            >
              {t("sendInquiry")}
            </Button>
          </>
        )}
      </Formik>
    </div>
  );
};
