import { useContext } from "react";
import "./App.css";
import { Navigation } from "./components/Navigation/Navigation";
import { HomePage } from "./pages/HomePage";
import { NewsPage } from "./pages/NewsPage";
import { MyStashPage } from "./pages/MyStashPage";
import { DeliveryPage } from "./pages/DeliveryPage";
import { ProfilePage } from "./pages/ProfilePage";
import { ArticlePage } from "./pages/ArticlePage";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { CategoryPage } from "./pages/CategoryPage";
import { LevelPage } from "./pages/LevelPage";
import { SubscriptionPage } from "./pages/SubscriptionPage";
import { TermsPage } from "./pages/TermsPage";
import { PrivacyPage } from "./pages/PrivacyPage";
import { FaqPage } from "./pages/FaqPage";
import { CustomerServicePage } from "./pages/CustomerServicePage";
import { RegisterPage } from "./pages/RegisterPage";
import { LoginPage } from "./pages/LoginPage";
import {
  UserDetailsContext,
  UserDetailsProvider,
} from "./utils/UserDetailsProvider";
import LanguageProvider from "./utils/LanguageProvider";
import { PersonalDataPage } from "./pages/PersonalDataPage";
import { PaymentLogPage } from "./pages/PaymentLogPage";
import { ItemPage } from "./pages/ItemPage";
import { OneTimePaymentsPage } from "./pages/OneTimePaymentsPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MobileTabMenu } from "./components/MobileTabMenu/MobileTabMenu";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <UserDetailsProvider>
          <AppContent />
        </UserDetailsProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
}

function AppContent() {
  const userContext = useContext(UserDetailsContext);

  if (!userContext) {
    return null;
  }

  const { userDetails } = userContext;
  const currentYear = new Date().getFullYear();

  return (
    <BrowserRouter>
      <div className="App mx-auto bg-gray-100 text-gray-700 min-h-[calc(100vh-180px)]">
        <Navigation />
        <Routes>
          <Route path="/" element={<ProtectedRoute element={<HomePage />} />} />
          <Route
            path="/subscription"
            element={<ProtectedRoute element={<SubscriptionPage />} />}
          />
          <Route
            path="/category/:categoryCode"
            element={<ProtectedRoute element={<CategoryPage />} />}
          />
          <Route
            path="/level/:levelCode"
            element={<ProtectedRoute element={<LevelPage />} />}
          />
          <Route
            path="/news"
            element={<ProtectedRoute element={<NewsPage />} />}
          />
          <Route
            path="/news/:newsCode"
            element={<ProtectedRoute element={<ArticlePage />} />}
          />
          <Route
            path="/my-stash"
            element={<ProtectedRoute element={<MyStashPage />} />}
          />
          <Route
            path="/my-stash/:itemCode"
            element={<ProtectedRoute element={<ItemPage />} />}
          />
          <Route
            path="/delivery"
            element={<ProtectedRoute element={<DeliveryPage />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<ProfilePage />} />}
          />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route
            path="/hr/privacy-policy"
            element={<Navigate to="/privacy" />}
          />
          <Route
            path="/en/privacy-policy"
            element={<Navigate to="/privacy" />}
          />
          <Route path="/faq" element={<FaqPage />} />
          <Route
            path="/hr/terms-and-conditions"
            element={<Navigate to="/terms" />}
          />
          <Route
            path="/en/terms-and-conditions"
            element={<Navigate to="/terms" />}
          />
          <Route path="/customer-service" element={<CustomerServicePage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/en/faq" element={<Navigate to="/faq" />} />
          <Route path="/hr/faq" element={<Navigate to="/faq" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/personal-data"
            element={<ProtectedRoute element={<PersonalDataPage />} />}
          />
          <Route
            path="/payment-log"
            element={<ProtectedRoute element={<PaymentLogPage />} />}
          />
          <Route
            path="/one-time-payments"
            element={<ProtectedRoute element={<OneTimePaymentsPage />} />}
          />
        </Routes>
        {userDetails && <MobileTabMenu />}
        <Footer
          companyName={"Nordia Digital"}
          year={currentYear}
          privacyLink="privacy"
          termsLink="terms"
          faqLink="faq"
          customerServiceLink="customer-service"
          isAuthenticated={!!userDetails}
        />
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar
        />
      </div>
    </BrowserRouter>
  );
}

function ProtectedRoute({ element }) {
  const userContext = useContext(UserDetailsContext);

  if (!userContext) {
    return <Navigate to="/login" />;
  }

  const { userDetails } = userContext;

  return userDetails ? element : <Navigate to="/login" />;
}

export default App;
