import React from "react";

interface CategoryItemProps {
    name: string,
}

export const CategoryItem: React.FC<CategoryItemProps> = ({name}) => {
    return(
        <div className="w-full bg-white rounded-xl p-4 content-center">
            <p className="text-emerald-500 font-semibold whitespace-pre-line text-left">{name}</p>
        </div>
    )
}