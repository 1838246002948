import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../../utils/LanguageProvider";
import { UserDetailsContext } from "../../utils/UserDetailsProvider";

export const MobileTabMenu: React.FC = () => {
  const location = useLocation();
  const { t } = useContext(LanguageContext);
  const { userDetails } = useContext(UserDetailsContext);

  const pagesList = [
    { page: t("homeScreenTitle"), link: "" },
    { page: t("newsTitle"), link: "news" },
    { page: t("myStash"), link: "my-stash" },
    { page: t("deliveryTitle"), link: "delivery" },
    userDetails
      ? { page: t("profile"), link: "profile" }
      : { page: t("login"), link: "login" },
  ];

  return (
    <div className="md:hidden fixed bottom-0 min-h-[50px] left-0 w-full bg-emerald-500 text-white flex justify-around py-3 border-t border-white">
      {pagesList.map((page, index) => {
        const isActive = location.pathname === `/${page.link}`;
        return (
          <Link
            key={index}
            to={`/${page.link}`}
            className={`flex flex-col items-center my-2 ${
              isActive ? "text-gray-200" : "text-gray-300"
            }`}>
            <span key={index}
              className={`text-lg ${isActive ? "font-bold" : "font-normal"}`}>
              {page.page}
            </span>
          </Link>
        );
      })}
    </div>
  );
};
