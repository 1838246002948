import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCustomer, useGetSubscriptions } from "../services";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import { LanguageContext } from "../utils/LanguageProvider";
import { Loader } from "../components/Loader/Loader";
import { BackButton } from "../components/BackButton/BackButton";
import { Button } from "../components/Button/Button";
import Modal from "react-modal";
import { FaArrowLeft } from "react-icons/fa";
import createSubscriptionSession from "../utils/StripeService";

export const SubscriptionPage = () => {
  const navigate = useNavigate();
  const { userDetails, updateUserDetails } = useContext(UserDetailsContext);
  const { t, language } = useContext(LanguageContext);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);

  const { data: uniqueCustomer } = useGetCustomer({
    customerId: userDetails?.uid,
  });

  const {
    data,
    isLoading: isSubscriptionDescriptionsLoading,
    isError,
    error,
  } = useGetSubscriptions();

  if (isError) {
    return <p>Error: {error?.message}</p>;
  }

  const updateLevel = async () => {
    try {
      const customerId = uniqueCustomer.id;
      const result = await fetch(
        "https://us-central1-survstash-f265b.cloudfunctions.net/updateUserLevelManually",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: customerId,
          }),
        }
      );
      const responseData = await result.json();
      console.log("Cloud Function Response:", responseData);
    } catch (error) {
      console.error("Error calling Cloud Function:", error);
    }
  };

  const handleSubscriptionClick = (subscriptionType: any) => {
    console.log("sfsese", subscriptionType);
    const priceId =
      subscriptionType === "Expert Pretplata"
        ? "price_1PJGuTG2MFgbmDmXgv5zvcCL"
        : subscriptionType === "Advanced Pretplata"
        ? "price_1PJGuRG2MFgbmDmXSmuRJGpb"
        : "price_1PJGuOG2MFgbmDmXK908qNJp";

    setSelectedSubscription({ type: subscriptionType, priceId: priceId });
  };

  const handleSubscribe = async () => {
    setIsSubscriptionLoading(true);
    if (selectedSubscription) {
      console.log("Selected subscription:", selectedSubscription);
      await createSubscriptionSession(
        selectedSubscription.priceId,
        setIsSubscriptionLoading
      );
    } else {
      setIsSubscriptionLoading(false);
    }
  };

  return (
    <div className="p-6 container max-w-[900px] px-5 md:mx-auto">
      {(isSubscriptionDescriptionsLoading || isCancelLoading) && (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      )}

      <div className="flex justify-start">
        <div onClick={() => navigate(-1)}>
          <BackButton className="w-10 h-10">
            <FaArrowLeft className="mx-auto text-white" />
          </BackButton>
        </div>
      </div>

      <h2 className="text-xl font-semibold mb-4">{t("chooseSubscription")}</h2>
      <div className="space-y-4">
        {data?.map((subscription, index) => (
          <div
            key={index}
            onClick={() =>
              handleSubscriptionClick(subscription.subscriptionNameEn)
            }
            className="w-full border-2 rounded-lg p-4 border-emerald-500 bg-white text-center cursor-pointer hover:scale-105 transition-transform duration-200"
          >
            <Button className="bg-emerald-500 text-white p-2 rounded mb-2">
              <h3 className="text-lg font-semibold">
                {subscription.subscriptionNameEn}
              </h3>
            </Button>
            <p className="font-bold">{`${subscription.subscriptionPrice} ${
              language === "hr" ? "eur/mjesec" : "eur/month"
            }`}</p>
            <p className="text-justify my-2">
              {language === "hr"
                ? subscription.subscriptionDescriptionHr
                : subscription.subscriptionDescriptionEn}
            </p>
            <p className="font-medium">
              {language === "hr"
                ? subscription.subscriptionFreqNameHr
                : subscription.subscriptionFreqNameEn}
            </p>
          </div>
        ))}
      </div>

      {selectedSubscription && (
        <Modal
          isOpen={!!selectedSubscription}
          onRequestClose={() => setSelectedSubscription(null)}
          className="fixed inset-0 flex items-center justify-center z-50"
          overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-50"
        >
          <div className="bg-white p-6 rounded shadow-lg max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">{`${
              language === "hr" ? "Plaćanje - " : "Payment for"
            } ${selectedSubscription.type}`}</h2>
            {!isSubscriptionLoading ? (
              <Button
                onClick={handleSubscribe}
                className="bg-emerald-500 text-white p-2 rounded mt-4"
              >
                {language === "hr" ? "Pokreni Pretplatu" : "Start Subscription"}
              </Button>
            ) : (
              <div className="spinner"></div>
            )}
            <Button
              onClick={() => setSelectedSubscription(null)}
              className="mt-4 w-full bg-gray-300 text-gray-800 p-2 rounded"
            >
              {language === "hr" ? "Zatvori" : "Close"}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
