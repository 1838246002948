import React from 'react';
import { Link } from 'react-router-dom';

interface NewsArticleProps {
    newsCode: string,
    imageLink: string,
    categories: string[],
    date: string,
    title: string,
    className?: string,
    imageClassName?: string
}

export const NewsArticle: React.FC<NewsArticleProps> = ({ newsCode, imageLink, categories, date, title, className, imageClassName }) => {
  return (
    <Link to={`/news/${newsCode}`}>
      <div className={`${className} flex md:hover:scale-105 min-h-32 duration-300 md:hover:cursor-pointer flex-row my-6 md:my-8 md:min-h-[150px] md:gap-8 w-full bg-white shadow-md rounded-lg`}>
        <img 
          src={`${imageLink}`}
          alt="Survival Stories" 
          className={`${imageClassName} object-cover h-auto rounded-l-lg`}
        />
        <div className="flex flex-col md:my-4 w-full text-left p-4 justify-start gap-1">
          <div className='flex flex-row gap-6'>
              {categories.map((category, index) => (
                  <React.Fragment key={index}>
                      <h5 className="text-md min-w-[100px] font-semibold">{category}</h5>
                      {index < categories.length - 1 && "|"}
                  </React.Fragment>
              ))}
          </div>
          <p className="text-emerald-500">{date}</p>
          <h1 className="md:text-xl font-bold line-clamp-2">{title}</h1>
        </div>
      </div>
    </Link>
  );
};
