import React from "react";

interface BackButtonProps {
    children: React.ReactNode,
    className?: string,
}

export const BackButton: React.FC<BackButtonProps> = ({ children, className }) => {
    return(
        <button className={`bg-emerald-500 mb-2 hover:bg-emerald-600 rounded-xl min-h-10 min-w-10 ${className}`}>{children}</button>
    )
}