import React, { FC, useEffect, useState, useCallback } from "react";
import { User as FirebaseUser } from "firebase/auth";
import { auth, db } from "../services/config/firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { Loader } from "../components/Loader/Loader";

interface UserDetailsProviderProps {
  children?: React.ReactNode;
}

interface UserDetailsProviderData {
  userDetails: UserAndCustomClaimRole | null;
  updateUserDetails: (user?: FirebaseUser | null) => void;
}

export interface UserAndCustomClaimRole extends FirebaseUser {
  customClaimRole: string;
  level: number;
  totalPaymentAmount: number;
  name: string;
  lastName: string;
  phoneNumber: string;
  address?: {
    city?: string;
    street?: string;
    postalCode?: string;
  };
  customerCustom3?: string;
  customerCustom2?: string;
  customerCustom1?: string;
  callingCode?: string;
  regularPartOfPhoneNumber?: string;
}

export const UserDetailsContext =
  React.createContext<UserDetailsProviderData | null>(null);

export const UserDetailsProvider: FC<UserDetailsProviderProps> = ({
  children,
}) => {
  const [userDetails, setUserDetails] = useState<UserAndCustomClaimRole | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Function to get custom claim role
  const getCustomClaimRole = useCallback(async (): Promise<string | null> => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) return null;

      await currentUser.getIdToken(true);
      const decodedToken = await currentUser.getIdTokenResult();
      const { stripeRole } = decodedToken?.claims;

      return typeof stripeRole === "string" &&
        ["Basic", "Advanced", "Expert"].includes(stripeRole)
        ? stripeRole
        : "Not subscribed";
    } catch (error) {
      console.error("Error fetching custom claim role:", error);
      return null;
    }
  }, []);

  // Function to fetch user details
  const fetchUserDetails = useCallback(
    async (user: FirebaseUser) => {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const customClaimRole = await getCustomClaimRole();

          const userAndCustomClaimRole: UserAndCustomClaimRole = {
            ...user,
            customClaimRole: customClaimRole || "Not subscribed",
            level: userData?.level || 0,
            totalPaymentAmount: userData?.totalPaymentAmount || 0,
            name: userData?.name || "",
            lastName: userData?.lastName || "",
            phoneNumber: userData?.phoneNumber || "",
            address: userData?.address || {
              city: "",
              street: "",
              postalCode: "",
            },
            callingCode: userData?.callingCode || "",
            regularPartOfPhoneNumber: userData?.regularPartOfPhoneNumber || "",
          };

          setUserDetails(userAndCustomClaimRole);
          localStorage.setItem("user", JSON.stringify(userAndCustomClaimRole));
        } else {
          console.log("User document not found");
          setUserDetails(null);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Failed to fetch user details");
      } finally {
        setLoading(false);
      }
    },
    [getCustomClaimRole]
  );

  // Function to update user details
  const updateUserDetails = useCallback(() => {
    const user = auth.currentUser;
    if (user) {
      setLoading(true);
      fetchUserDetails(user);
    } else {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        setUserDetails(JSON.parse(storedUser));
      } else {
        setUserDetails(null);
      }
      setLoading(false);
    }
  }, [fetchUserDetails]);

  // Set up Firestore listener for the user's document
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const userDocRef = doc(db, "users", user.uid);
    const unsubscribeFirestore = onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        setUserDetails((prevDetails) => {
          if (!prevDetails) return null;
          return {
            ...prevDetails,
            ...userData, // Update user details with the latest data
            level: userData?.level || prevDetails.level, // Update specific fields like level
            totalPaymentAmount:
              userData?.totalPaymentAmount || prevDetails.totalPaymentAmount,
          };
        });
      } else {
        console.log("User document not found");
        setUserDetails(null);
      }
    });

    return () => {
      unsubscribeFirestore();
    };
  }, []);

  // Listen to changes in auth state
  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        updateUserDetails();
      } else {
        setUserDetails(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, [updateUserDetails]);

  if (loading) {
    return <Loader />;
  }

  return (
    <UserDetailsContext.Provider value={{ userDetails, updateUserDetails }}>
      {children}
    </UserDetailsContext.Provider>
  );
};
