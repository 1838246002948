import React from "react";

interface InputProps {
    type: string,
    placeholder: string,
    value: string,
    name?: string,
    onChange: (e: any) => void,
    className?: string,
    pattern?: string
    disabled?: boolean,
    required?: boolean
    
}

export const Input: React.FC<InputProps> = ({ type, placeholder, value, onChange, name, className, pattern, disabled, required }) => {
    return(
        <input
            type={`${type}`}
            name={name}
            className={`w-full ${className} my-2 px-4 h-12 py-2 bg-white text-sm shadow-xl rounded-lg focus:outline-none focus:ring`}
            placeholder={`${placeholder}`}
            value={value}
            onChange={onChange}
            pattern={pattern}
            disabled={disabled}
            required={required}
        />
    )
}