import React, { useState, useEffect, useRef } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

interface FilterDropdownProps {
  options: string[];
  onSelect: (option: string) => void;
  title: string;
}

export const NewsFilter: React.FC<FilterDropdownProps> = ({ title, options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block md:px-0 w-full text-left md:w-[400px]" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex text-gray-400 font-normal justify-between w-full rounded-xl border-b border-gray-300 shadow-sm px-4 py-2 bg-white text-sm hover:bg-gray-50 focus:outline-none"
          onClick={toggleDropdown}
        >
          {selectedOption || `${title}`}
          {isOpen ? <FaArrowUp className="my-auto" /> : <FaArrowDown className="my-auto" />}
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute w-full right-0 mt-2 rounded-xl shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {options.map((option, index) => (
              <button
                key={index}
                className="block px-4 py-2 text-sm text-gray-700 w-full text-left hover:bg-gray-100"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};