import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";

const getSubscriptions = async () => {
  try {
    const subscriptionsCollection = collection(db, "subscription");

    const subscriptionsSnapshot = await getDocs(subscriptionsCollection);

    const subscriptions: any = subscriptionsSnapshot.docs.map((doc) => {
      const dataWithId = doc.data() as any;
      return { id: doc.id, ...dataWithId };
    });

    return { status: true, subscriptions };
  } catch (error) {
    console.log("error", error);
    return { status: false, message: "Failed to get subscriptions", error };
  }
};

const subscriptionService = {
  getSubscriptions,
};

export default subscriptionService;
