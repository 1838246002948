import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import levelsService from "../api/levelsApi";
import { queryClient } from "../../../App";

interface GetlevelsProps {
  options?: UseQueryOptions<any[], Error>;
}

export function useGetLevelsRQ({ options }: GetlevelsProps = {}) {
  return useQuery<any[], Error>(
    ["getLevels"],
    async () => {
      const { status, message, levels } = await levelsService.getLevels();

      if (status) {
        return levels as any[];
      } else {
        throw new Error(message);
      }
    },
    options
  );
}

interface GetlevelsProps {
  startLevelCode?: number;
  options?: UseQueryOptions<any[], Error>;
}

export function useGetLevelsFromCode({
  startLevelCode,
  options,
}: GetlevelsProps = {}) {
  return useQuery<any[], Error>(
    ["getLevels", startLevelCode],
    async () => {
      let response: any;
      if (startLevelCode) {
        response = await levelsService.getLevelsFromLevelCode(startLevelCode);
      } else {
        response = await levelsService.getLevels();
      }

      const { status, message, levels } = response;

      if (status) {
        return levels as any[];
      } else {
        throw new Error(message);
      }
    },
    options
  );
}

interface GetAllItemsForUserProps {
  userLevelCode: number;
  options?: UseQueryOptions<any[], Error>;
}

export function useGetAllItemsForUser({
  userLevelCode,
  options,
}: GetAllItemsForUserProps) {
  return useQuery<any[], Error>(
    ["getAllItemsForUser", userLevelCode],
    async () => {
      const response = await levelsService.getAllItemsForUser(userLevelCode);
      const { status, message, items } = response;

      if (status) {
        return items as any[];
      } else {
        throw new Error(message);
      }
    },
    {
      ...options,
      onSettled: (data, error) => {},
      onSuccess: (data) => {
        queryClient.invalidateQueries(["activeSubscription"]);
      },
      staleTime: 0,
    }
  );
}
