import React, { useContext } from "react";
import { LanguageContext } from "../../utils/LanguageProvider";

interface newBadgeProps {
    className?: string
}

export const NewBadge: React.FC<newBadgeProps> = ({className}) => {
    const { t } = useContext(LanguageContext)
    return(
        <p className={`bg-orange-500 ${className} my-0 text-white text-xs mx-auto px-2 rounded-2xl`}>{t("new")}</p>
    )
} 