import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import subscriptionService from "../api/customerSubscription";
import { ISubscriptionData } from "../../../types";
import { queryClient } from "../../../App";

interface GetActiveSubscriptionsProps {
  customerId: string;
  options?: UseQueryOptions<ISubscriptionData, Error>;
}

export function useGetActiveSubscription({
  customerId,
  options,
}: GetActiveSubscriptionsProps) {
  return useQuery<any, Error>(
    ["activeSubscription", customerId],
    async () => {
      const { status, activeSubscription, message, error, unsubscribe } =
        await subscriptionService.getActiveSubscriptionForCustomer(
          customerId,
          handleSubscriptionUpdate
        );

      if (!unsubscribe) {
      }

      if (status && activeSubscription) {
        return activeSubscription;
      } else {
        throw new Error(message ?? "Failed to fetch active subscription");
      }
    },
    {
      ...options,
      onSettled: (data, error) => {},
      onSuccess: (data) => {
        queryClient.invalidateQueries(["getLevels"]);
      },
      staleTime: 0,
    }
  );
}

const handleSubscriptionUpdate = (
  updatedSubscription: any,
  customerId: any
) => {
  queryClient.setQueryData(
    ["activeSubscription", customerId],
    updatedSubscription
  );

  queryClient.setQueryData(
    ["activeSubscription", customerId],
    (activeSubscription) => updatedSubscription
  );

  // queryClient.invalidateQueries(["activeSubscription", customerId]);

  // Here you can update the React Query cache with the new subscription data
  // This function will be called whenever there's an update to the active subscription
};

// Export the callback function if needed elsewhere
export { handleSubscriptionUpdate };
