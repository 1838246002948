import React, { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { Button } from "../components/Button/Button";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../components/BackButton/BackButton";
import { LanguageContext } from "../utils/LanguageProvider";
import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { db } from "../services";
import { UserDetailsContext } from "../utils/UserDetailsProvider";

// Test public key for Stripe
const stripePromise = loadStripe(
  "pk_live_51OpapGG2MFgbmDmXvzKcYxHTFN3V0KfSBuW1xtDMApPJ5aOqca3gFNd3GmSxYTyFG3KTmCjMX2OdxYbegFvrsYDe00gagHUE9M"
  // "pk_test_51OpapGG2MFgbmDmX7YghYeFGW60arMMjI4SEBH9JYejst53EMOcxz2YS0ROYUdVb3lwiHHfXQHmQ2WiXwBudlL3U009JgXv2RY"
);

const CheckoutForm: React.FC<{
  price: number;
  priceId: string;
  onSuccess: () => void;
  onCancel: () => void;
}> = ({ price, priceId, onSuccess, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { userDetails } = useContext(UserDetailsContext);
  const { t } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState<{ sessionId?: string }>();
  const [name, setName] = useState("");
  const [billingAddress, setBillingAddress] = useState({
    street: "",
    city: "",
    zip: "",
  });

  // Correct the function to include the Stripe price ID
  const createSession = async ({ uid, priceId }) => {
    try {
      const sessionData = {
        client: "web",
        mode: "payment",
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      };

      const checkoutSessionCollection = collection(
        db,
        "customers",
        uid,
        "checkout_sessions"
      );
      const checkoutSessionRef = await addDoc(
        checkoutSessionCollection,
        sessionData
      );
      return checkoutSessionRef; // Return the document reference to listen for updates
    } catch (error) {
      console.error("Error creating checkout session:", error);
      throw error;
    }
  };

  const handlePayment = async () => {
    try {
      setIsLoading(true);
      if (userDetails?.uid) {
        // Pass a valid price ID from Stripe for the one-time payment
        const checkoutSessionRef = await createSession({
          uid: userDetails.uid,
          priceId: priceId, // Replace with your actual Stripe price ID
        });

        console.log("TEST", userDetails.uid);
        console.log("TEST", priceId);

        // Listen for updates to the session to get the sessionId
        onSnapshot(checkoutSessionRef, (doc) => {
          const data = doc.data();
          console.log("hbbhbhbhhbk", data?.sessionId);

          if (data?.sessionId) {
            setPaymentData({ sessionId: data.sessionId });
          }
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentData?.sessionId) {
      console.log("TEST", paymentData?.sessionId);
      const startPayment = async () => {
        setIsLoading(true);

        const stripe = await stripePromise;

        try {
          const { error } = await stripe.redirectToCheckout({
            sessionId: paymentData.sessionId,
          });

          if (error) {
            console.error("Error during payment:", error.message);
          }
        } catch (err) {
          console.error("Unexpected error:", err);
        } finally {
          setIsLoading(false);
        }
      };

      startPayment();
    }
  }, [paymentData]);

  return (
    /* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Name on Card
        </label>
        <input
          type="text"
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div> */
    /* <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          Billing Address
        </label>
        <input
          type="text"
          placeholder="Street Address"
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          value={billingAddress.street}
          onChange={(e) =>
            setBillingAddress({ ...billingAddress, street: e.target.value })
          }
          required
        />
        <input
          type="text"
          placeholder="City"
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          value={billingAddress.city}
          onChange={(e) =>
            setBillingAddress({ ...billingAddress, city: e.target.value })
          }
          required
        />
        <input
          type="number"
          placeholder="Postal Code"
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          value={billingAddress.zip}
          onChange={(e) =>
            setBillingAddress({ ...billingAddress, zip: e.target.value })
          }
          required
        />
      </div> */
    <div className="flex justify-end">
      <Button
        type="button"
        onClick={handlePayment}
        className="bg-emerald-500 hover:bg-emerald-600 px-1 text-white "
        disabled={isLoading}
      >
        {t("pay") + " " + price + ".00 Eur"}
      </Button>
      <Button
        type="button"
        onClick={onCancel}
        className="bg-gray-500 hover:bg-gray-600 text-white ml-2"
      >
        {t("cancelSubmission")}
      </Button>
    </div>
  );
};

// One-time Payments Page Component
export const OneTimePaymentsPage: React.FC = () => {
  const { t } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const [priceId, setPriceId] = useState<string>();

  const [showCheckoutForm, setShowCheckoutForm] = useState(false);

  const handleOneLevelPayment = () => {
    setSelectedLevel(1);
    setPrice(16.0);
    setPriceId("price_1Q9jQOG2MFgbmDmXQKMU1nYM");
    setShowCheckoutForm(true);
  };

  const handleTwoLevelsPayment = () => {
    setSelectedLevel(2);
    setPrice(32.0);
    setPriceId("price_1Q9jQKG2MFgbmDmX90efCEPw");
    setShowCheckoutForm(true);
  };

  const handleThreeLevelsPayment = () => {
    setSelectedLevel(3);
    setPrice(48.0);
    setPriceId("price_1Q9jQEG2MFgbmDmXA1UrwI3X");
    setShowCheckoutForm(true);
  };

  const handleFourLevelsPayment = () => {
    setSelectedLevel(4);
    setPrice(64.0);
    setPriceId("price_1Q9jQ8G2MFgbmDmXTUqYoYql");
    setShowCheckoutForm(true);
  };

  //TEST:

  // const handleOneLevelPayment = () => {
  //   setSelectedLevel(1);
  //   setPrice(16.0);
  //   setPriceId("price_1Pwg7TG2MFgbmDmXKJS2FIRH");
  //   setShowCheckoutForm(true);
  // };

  // const handleTwoLevelsPayment = () => {
  //   setSelectedLevel(2);
  //   setPrice(32.0);
  //   setPriceId("price_1Pwq2nG2MFgbmDmXQIFWpRSh");
  //   setShowCheckoutForm(true);
  // };

  // const handleThreeLevelsPayment = () => {
  //   setSelectedLevel(3);
  //   setPrice(48.0);
  //   setPriceId("price_1Pwq35G2MFgbmDmX8IkwWO06");
  //   setShowCheckoutForm(true);
  // };

  // const handleFourLevelsPayment = () => {
  //   setSelectedLevel(4);
  //   setPrice(64.0);
  //   setPriceId("price_1Pwq3MG2MFgbmDmXvjxkBrjM");
  //   setShowCheckoutForm(true);
  // };

  const closeCheckoutForm = () => {
    setShowCheckoutForm(false);
    setPrice(null);
    setSelectedLevel(null);
  };

  return (
    <div className="container max-w-[600px] px-5 my-5 md:mx-auto">
      <div className="flex justify-start">
        <div onClick={() => navigate(-1)}>
          <BackButton className="w-10 h-10">
            <FaArrowLeft className="mx-auto text-white" />
          </BackButton>
        </div>
      </div>
      <h1 className="font-semibold text-xl my-4">{t("chooseLevelAmount")}</h1>

      <div
        onClick={handleOneLevelPayment}
        className="border-2 border-emerald-500 rounded-lg cursor-pointer md:hover:scale-105 duration-200 bg-white shadow-xl p-4 my-5"
      >
        <Button className="bg-emerald-500 text-white">{t("oneL")}</Button>
        <h2 className="font-bold my-2">16 Eur</h2>
        <p>{t("oneLevel")}</p>
      </div>

      <div
        onClick={handleTwoLevelsPayment}
        className="border-2 border-emerald-500 rounded-lg cursor-pointer md:hover:scale-105 duration-200 bg-white shadow-xl p-4 my-5"
      >
        <Button className="bg-emerald-500 text-white">{t("twoL")}</Button>
        <h2 className="font-bold my-2">32 Eur</h2>
        <p>{t("twoLevel")}</p>
      </div>

      <div
        onClick={handleThreeLevelsPayment}
        className="border-2 border-emerald-500 rounded-lg cursor-pointer md:hover:scale-105 duration-200 bg-white shadow-xl p-4 my-5"
      >
        <Button className="bg-emerald-500 text-white">{t("threeL")}</Button>
        <h2 className="font-bold my-2">48 Eur</h2>
        <p>{t("threeLevel")}</p>
      </div>

      <div
        onClick={handleFourLevelsPayment}
        className="border-2 border-emerald-500 rounded-lg cursor-pointer md:hover:scale-105 duration-200 bg-white shadow-xl p-4 my-5"
      >
        <Button className="bg-emerald-500 text-white">{t("fourL")}</Button>
        <h2 className="font-bold my-2">64 Eur</h2>
        <p>{t("fourLevel")}</p>
      </div>

      {showCheckoutForm && price && (
        <div className="fixed inset-0 flex pt-10 items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
            <Elements stripe={stripePromise}>
              <CheckoutForm
                price={price}
                priceId={priceId}
                onSuccess={() => closeCheckoutForm()}
                onCancel={() => closeCheckoutForm()}
              />
            </Elements>
          </div>
        </div>
      )}
    </div>
  );
};
