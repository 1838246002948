import React, { useState, useContext } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../services/config/firebase";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../utils/LanguageProvider";
import { Button } from "../components/Button/Button";
import { Loader } from "../components/Loader/Loader";

export const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useContext(LanguageContext);

  const handleResetPassword = async () => {
    if (!email) {
      setError(t("pleaseEnterEmail"));
      return;
    }
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setTimeout(() => navigate("/"), 3000); // Redirect after 3 seconds
    } catch (err) {
      setError(t("failedToSendResetEmail"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container max-w-[600px] mx-auto px-5 py-6 mb-5 flex flex-col justify-center min-h-[calc(100vh-200px)]">
      <h1 className="text-2xl font-semibold">{t("resetYourPassword")}</h1>
      {error && <p className="text-red-500">{error}</p>}
      {success && <p className="text-green-500">{t("checkYourEmail")}</p>}
      <input
        className="rounded-md h-12 p-3 border border-emerald-600"
        type="email"
        placeholder={t("enterEmail")}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        className="bg-emerald-500 hover:bg-emerald-600 rounded-lg text-white mt-4"
        onClick={handleResetPassword}
        disabled={loading}
      >
        {loading ? <Loader /> : t("resetPassword")}
      </Button>
    </div>
  );
};
