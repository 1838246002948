import React from "react";
import { NavigationProps } from "./Navigation";
import { Link, useLocation } from "react-router-dom";

export const Pages: React.FC<NavigationProps> = ({pages, onClick}) => {
  const location = useLocation(); 
    return(
        <>
            {pages.map((page, index) => {
              const isActive = location.pathname === `/${page.link}`; 
              return(
                <div key={index}>
                  <li
                    key={index}
                    className={`md:font-semibold font-normal ${isActive ? "text-[32px] font-semibold md:text-lg" : ""} text-xl md:text-lg hover:scale-110 transition-transform duration-100 px-4 lg:px-6 py-2 rounded cursor-pointer`}
                    >
                    <Link key={index} to={`/${page.link}`} onClick={onClick}>
                      {page.page}
                    </Link>
                  </li>
                    {index < pages.length - 1 && (
                      <hr className="h-0.2 w-[80%] md:hidden bg-white" />
                    )}
                </div>
              )
            })}
        </>
    )
}