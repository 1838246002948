import React, { useContext, useEffect, useState } from "react";
import { NewsFilter } from "../components/NewsFilter/NewsFilter";
import { NewsArticle } from "../components/NewsArticle/NewsArticle";
import { LanguageContext } from "../utils/LanguageProvider";
import { Loader } from "../components/Loader/Loader";
import { useGetCustomer, useGetNews } from "../services";
import { UserDetailsContext } from "../utils/UserDetailsProvider";

export interface Article {
  category: {
    newsCategoryTitleHr: string;
    newsCategoryTitleEn: string;
    newsCategoryCode: string;
  };
  newsCode: string;
  newsDate: number;
  newsDescriptionEn: string;
  newsDescriptionHr: string;
  newsPicture: string;
  newsTitleEn: string;
  newsTitleHr: string;
  newsType: string;
  pinned: string;
}

export const NewsPage: React.FC = () => {
  const { language, t } = useContext(LanguageContext);
  const [filteredArticles, setFilteredArticles] = useState<Article[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>(language === "en" ? "All" : "Sve");
  const [loading, setLoading] = useState<boolean>(false);
  const { userDetails } = useContext(UserDetailsContext);

  const currentLanguage = language as "en" | "hr";

  // Fetch customer data
  const {
    data: uniqueCustomer,
    isLoading: isCustomersLoading,
    isError: isCustomersError,
    error: customerError,
    refetch: customerRefetch,
  } = useGetCustomer({ customerId: userDetails?.uid });

  // Fetch news data
  const {
    data,
    isLoading: isNewsLoading,
    isError: isNewsError,
    error,
    refetch,
  } = useGetNews({
    newsCodes: uniqueCustomer?.newsCode?.split(";"),
    newsTypes: options?.map((type) => type),
    language: currentLanguage, // Use the correct type here
  });

  // Handle filtering
  const filterArticles = (category: string) => {
    if (!data) return;

    if (category === "All" || category === "Sve") {
      setFilteredArticles(data);
    } else {
      const filtered = data.filter((article: Article) => {
        const categoryTitle =
          currentLanguage === "hr"
            ? article.category.newsCategoryTitleHr
            : article.category.newsCategoryTitleEn;
        return categoryTitle === category;
      });
      setFilteredArticles(filtered);
    }
  };

  // Handle option select
  const handleSelect = (option: string) => {
    setSelectedOption(option);
    filterArticles(option);
  };

  useEffect(() => {
    if (data) {
      console.log(data);

      const uniqueCategories = Array.from(
        new Set(
          data.map((article) =>
            currentLanguage === "hr"
              ? article.category.newsCategoryTitleHr
              : article.category.newsCategoryTitleEn
          )
        )
      );

      console.log(uniqueCategories);

      setOptions([
        currentLanguage === "en" ? "All" : "Sve",
        ...uniqueCategories,
      ]);

      setFilteredArticles(data);

      setSelectedOption(currentLanguage === "en" ? "All" : "Sve");
    }
  }, [data, currentLanguage]);

  if (isNewsLoading || loading) {
    return <Loader />;
  }

  return (
    <div className="max-w-[900px] min-h-[calc(100vh-200px)] md:px-5 md:mx-auto mx-5">
      <h1 className="text-xl font-bold pt-8 uppercase">{t("newsTitle")}</h1>
      <hr className="mb-10 h-0.5 mt-2 bg-gray-400" />
      <div className="flex flex-col justify-center items-center">
        <NewsFilter
          options={options}
          title={t("filterByNewsType")}
          onSelect={handleSelect}
        />
        <div className="md:mt-5 max-w-[900px] w-full">
          {filteredArticles.map((article, index) =>
            index === 0 ? (
              <NewsArticle
                newsCode={article.newsCode}
                className="min-h-64 flex-col md:pr-0 md:gap-0"
                imageClassName="w-full md:h-64 rounded-l-none rounded-t-lg"
                key={index}
                categories={[
                  currentLanguage === "en"
                    ? article.category.newsCategoryTitleEn
                    : article.category.newsCategoryTitleHr,
                ]}
                date={new Date(article.newsDate * 1000).toLocaleDateString()}
                title={
                  currentLanguage === "en"
                    ? article.newsTitleEn
                    : article.newsTitleHr
                }
                imageLink={article.newsPicture}
              />
            ) : (
              <NewsArticle
                newsCode={article.newsCode}
                className="md:pr-8"
                imageClassName="md:w-48 w-32"
                key={index}
                categories={[
                  currentLanguage === "en"
                    ? article.category.newsCategoryTitleEn
                    : article.category.newsCategoryTitleHr,
                ]}
                date={new Date(article.newsDate * 1000).toLocaleDateString()}
                title={
                  currentLanguage === "en"
                    ? article.newsTitleEn
                    : article.newsTitleHr
                }
                imageLink={article.newsPicture}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};
