import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../utils/LanguageProvider";

export const WelcomeComponent: React.FC = () => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="flex flex-col md:flex-row gap-8 px-4 my-10 pb-8 md:mx-auto">
      <div className="flex flex-col items-center mx-3 min-h-[120px] basis-1/3">
        <span className="text-[82px] border-2 shadow-xl border-emerald-500 w-[130px] rounded-full text-emerald-500 flex items-center justify-center">
          1
        </span>
        <div>
          <h1 className="text-2xl h-[90px] content-center py-3 font-bold">
            {language === "hr"
              ? "Odaberite pretplatu"
              : "Select a subscription"}
          </h1>
          <p className="text-gray-600">
            {language === "hr"
              ? `Klikom na gumb "ODABERI" izaberite razinu pretplate koja određuje kojom brzinom raste NIVO vaših Zaliha.`
              : `By clicking on the "CHOOSE" button, select the subscription level that determines the rate at which your Stash LEVEL grows.`}
          </p>
        </div>
      </div>
      <div className="flex flex-col min-h-[120px] items-center mx-3 basis-1/3">
        <span className="text-[82px] border-2 shadow-xl border-emerald-500 w-[130px] rounded-full text-emerald-500 flex items-center justify-center">
          2
        </span>
        <div>
          <h1 className="text-2xl h-[90px] content-center py-3 font-bold">
            {language === "hr" ? "Povećajte Nivo vaših Zaliha" : "Increase the Level of your Supplies"}
          </h1>
          <p className="text-gray-600">
            {language === "hr"
              ? "Redovnim uplatama pretplatom, izvanrednim jednokratnim uplatama ili osvajanjem nagradnih bodova kroz natječaje u rubrici Vijesti."
              : "With regular subscription payments, extraordinary one-time payments or winning reward points through contests in the News section"}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center min-h-[120px] mx-3 basis-1/3">
        <span className="text-[82px] border-2 shadow-xl border-emerald-500 w-[130px] rounded-full text-emerald-500 flex items-center justify-center">
          3
        </span>
        <div>
          <h1 className="text-2xl py-3 h-[90px] content-center font-bold">
            {language === "hr" ? "Uživajte u svom Stashu" : "Enjoy your Stash"}
          </h1>
          <p className="text-gray-600">
            {language === "hr"
              ? "U trenutku potrebe, zatražite isporuku i uživajte u vašem Stashu! Što je stash većeg NIVO-a brojniji i raznovrsniji predmeti u vašoj zalihi vas čekaju!"
              : "In the moment of need, request delivery and enjoy your Stash! The higher the LEVEL stash, the more numerous and varied the items in your stash are waiting for you!"}
          </p>
        </div>
      </div>
    </div>
  );
};
