import React, { useContext } from "react";
import { LanguageContext } from "../../utils/LanguageProvider";

export const SpecialMessageLevels: React.FC = () => {
    const { language } = useContext(LanguageContext)

    return (
        <div className="md:mx-auto mb-7 rounded-xl bg-white border-2 border-yellow-300 shadow-xl p-5">
                <p className="font-semibold">{language === "en" ?
                    "Many more levels await you, they will become visible here once you have a higher level." 
                    : 
                    "Čekaju vas još mnoge razine, one će postati vidljive ovdje kada budete imali višu razinu."
                    }
                </p>
        </div>
    );
};
