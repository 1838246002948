import React, { useContext } from "react";
import { Button } from "../Button/Button";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../utils/LanguageProvider";

export const ProfileSites: React.FC = () => {
    const { t } = useContext(LanguageContext);

    return(
        <div className="flex flex-row gap-3">
            <div className="basis-1/2 flex flex-col gap-3">
                <Link to="/customer-service">
                    <Button className="text-white px-6 h-20 leading-5 bg-emerald-600 hover:bg-emerald-700">{t("customerService")}</Button>
                </Link>
                <Link to="/privacy">
                    <Button className="text-white px-6 h-20 leading-5 bg-emerald-600 hover:bg-emerald-700">{t("dataPrivacy")}</Button>
                </Link>
            </div>
            <div className="basis-1/2 flex flex-col gap-3">
                <Link to="/faq">
                    <Button className="text-white px-6 h-20 leading-5 bg-emerald-600 hover:bg-emerald-700">{t("faq")}</Button>
                </Link>
                <Link to="/terms">
                    <Button className="text-white px-6 h-20 leading-5 bg-emerald-600 hover:bg-emerald-700">{t("termsAndConditions")}</Button>
                </Link>
            </div>
        </div>
    )
}