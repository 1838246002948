import React from "react";

interface ButtonProps {
    children: React.ReactNode,
    className?: string,
    color?: string,
    onClick?: () => void | string | Promise<void>,
    type?: "submit" | "reset" | "button";
    value?: string,
    disabled?: boolean
}

export const Button: React.FC<ButtonProps> = ({ children, className, onClick, type, value, disabled }) => {
    return(
        <button disabled={disabled} value={value} onClick={onClick} type={type} className={`duration-100 py-3 px-10 rounded-xl w-full font-bold shadow-xl ${className}`}>{children}</button>
    )
}