interface ActiveSubscriptionCreated {
  seconds: number;
}

interface UserDetails {
  totalPaymentAmount: number;
  // Add other properties if needed
}

interface CalculateAvailableDateParams {
  activeSubscriptionRole: string;
  activeSubscriptionCreated: any;
  currentIndex: number;
  userDetails: UserDetails; // User details passed in
}

/**
 * Calculates the available date based on subscription role, creation date, and index.
 *
 * @param {CalculateAvailableDateParams} params - The parameters including subscription role, creation date, index, and user details.
 * @returns {string} - The formatted available date in DD.MM.YYYY format.
 */

export function calculateAvailableDate(
  activeSubscriptionRole,
  activeSubscriptionCreated,
  currentIndex,
  userDetails
) {
  console.log("currentIndex", currentIndex);
  // Convert baseDate to a Date object in UTC
  const baseDate = activeSubscriptionCreated
    ? new Date(activeSubscriptionCreated.seconds * 1000)
    : new Date();

  let monthsToAdd = 1;

  switch (activeSubscriptionRole) {
    case "Basic":
      if (userDetails?.totalPaymentAmount === 800) {
        monthsToAdd = currentIndex === 1 ? 1 : currentIndex * 2 - 1;
      } else if (
        userDetails?.totalPaymentAmount >= 1600 &&
        userDetails?.totalPaymentAmount % 1600 !== 0
      ) {
        monthsToAdd = currentIndex === 1 ? 1 : currentIndex * 2 - 1;
      } else {
        monthsToAdd = currentIndex * 2;
      }
      break;
    case "Advanced":
      monthsToAdd = +currentIndex;
      break;
    case "Expert":
      monthsToAdd = Math.floor((currentIndex - 1) / 2) + 1;
      break;
    default:
      monthsToAdd = 1;
      break;
  }

  // Adjusting the date correctly
  const nextDate = new Date(baseDate);
  nextDate.setUTCMonth(nextDate.getUTCMonth() + monthsToAdd);

  const availableDate = nextDate
    .toLocaleDateString("en-GB", {
      timeZone: "UTC",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");

  console.log("availableDate", availableDate);

  return availableDate;
}
