import {
  collection,
  getDocs,
  DocumentSnapshot,
  DocumentData,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { db } from "../../config";

interface SubscriptionResponse {
  status: boolean;
  activeSubscription?: any;
  message?: string;
  error?: any;
  unsubscribe?: Unsubscribe;
}

const returnActiveSubscription = async (customerId: string) => {
  const customerSubscriptionsRef = collection(
    db,
    "customers",
    customerId,
    "subscriptions"
  );

  const querySnapshot = await getDocs(customerSubscriptionsRef);

  let activeSubscription: DocumentSnapshot<DocumentData> | null = null;

  querySnapshot.forEach((doc) => {
    const subscriptionData = doc.data();
    if (subscriptionData.status === "active" && !activeSubscription) {
      activeSubscription = doc;
    }
  });

  if (!activeSubscription) {
    return {
      status: false,
      message: "No active subscriptions found for the customer",
      activeSubscription: null,
      activeSubscriptionObject: null,
    };
  }

  const activeSubscriptionData = activeSubscription.data();

  const activeSubscriptionObject = {
    ...activeSubscriptionData,
    id: activeSubscription.id,
  };

  return {
    activeSubscriptionObject,
    customerSubscriptionsRef,
  };
};

const getActiveSubscriptionForCustomer = async (
  customerId: string,
  onUpdate: (activeSubscription: any, customerId?: any) => void
): Promise<SubscriptionResponse> => {
  try {
    const { activeSubscriptionObject, customerSubscriptionsRef } =
      await returnActiveSubscription(customerId);

    if (!activeSubscriptionObject) {
      return {
        status: false,
        message: "No active subscriptions to listen to",
      };
    }

    const unsubscribe = onSnapshot(
      customerSubscriptionsRef,
      async (snapshot) => {
        let { activeSubscriptionObject } = await returnActiveSubscription(
          customerId
        );

        if (activeSubscriptionObject)
          onUpdate(activeSubscriptionObject, customerId);
        else {
          onUpdate(null);
        }
      }
    );

    return {
      status: true,
      activeSubscription: activeSubscriptionObject,
      unsubscribe,
    };
  } catch (error: any) {
    console.log("Error fetching active subscription for customer:", error);
    console.error("Error fetching active subscription for customer:", error);
    return {
      status: false,
      message: "Failed to get active subscription",
      error,
    };
  }
};

const subscriptionService = {
  getActiveSubscriptionForCustomer,
};

export default subscriptionService;