import React, { useContext, useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { LanguageContext } from "../utils/LanguageProvider";
import { Link } from "react-router-dom";
import { Button } from "../components/Button/Button";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import { db, useGetActiveSubscription, useGetCustomer } from "../services";
import { FaArrowLeft, FaCheck, FaTimes } from "react-icons/fa";
import { BackButton } from "../components/BackButton/BackButton";

const getCustomerWithPayments = async (customerId: string) => {
  try {
    const customerDocRef = doc(db, "customers", customerId);
    const customerDocSnap = await getDoc(customerDocRef);

    if (!customerDocSnap.exists()) {
      throw new Error("Customer not found");
    }

    const customerData = customerDocSnap.data();

    const paymentsCollectionRef = collection(customerDocRef, "payments");
    const paymentsQuerySnapshot = await getDocs(paymentsCollectionRef);
    const paymentsData = paymentsQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      status: true,
      customer: {
        id: customerDocSnap.id,
        ...customerData,
        payments: paymentsData,
      },
    };
  } catch (error) {
    console.error("Error fetching customer with payments:", error);
    return {
      status: false,
      message: "Failed to get customer with payments",
      error,
    };
  }
};


export const PaymentLogPage: React.FC = () => {
  const { t } = useContext(LanguageContext);
  const { userDetails } = useContext(UserDetailsContext);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data: activeSubscription, refetch } = useGetActiveSubscription({
    customerId: userDetails?.uid,
  });

  const isNotSubscribed = activeSubscription?.role;

  const formatAmount = (amount: number): string => {
    return (amount / 100).toFixed(2);
  };

  useEffect(() => {
    const fetchPaymentLogs = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await getCustomerWithPayments(userDetails?.uid);
        if (response.status) {
          setPaymentLogs(response.customer.payments);
        } else {
          setError(response.message);
        }
      } catch (err) {
        setError(t("errorFetchingLogs"));
      } finally {
        setIsLoading(false);
      }
    };

    if (isNotSubscribed) {
      fetchPaymentLogs();
    }
  }, [userDetails?.uid, isNotSubscribed, t]);

  if (isLoading) {
    return (
      <div className="container max-w-[900px] px-5 mt-5 pt-3 mx-auto min-h-[calc(100vh-200px)] flex flex-col gap-4">
        <h1 className="text-xl font-bold pt-8 uppercase">{t("paymentLog")}.</h1>
        <hr className="md:mb-10 h-0.5 mx-10 mt-2 bg-gray-400" />
        <p>{t("loading")}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container max-w-[900px] px-5 mt-5 pt-3 mx-auto min-h-[calc(100vh-200px)] flex flex-col gap-4">
        <h1 className="text-xl font-bold pt-8 uppercase">{t("paymentLog")}.</h1>
        <hr className="md:mb-10 h-0.5 mx-10 mt-2 bg-gray-400" />
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="container max-w-[900px] px-5 mt-5 mx-auto min-h-[calc(100vh-200px)]">
        <div className="flex justify-start">
            <Link to="/profile">
                <BackButton className="w-10 h-10">
                    <FaArrowLeft className="mx-auto text-white"/>
                </BackButton>
            </Link>
        </div>
      <h1 className="text-xl font-bold md:pt-8 pt-3 uppercase">{t("paymentLog")}</h1>
      <hr className="md:mb-10 h-0.5 mx-2 mt-2 bg-gray-400" />
      {!isNotSubscribed ? (
        <div className="my-5 flex flex-col gap-4">
          <p className="text-emerald-600 font-medium">{t("noPaymentsFound")}</p>
          <p>{t("paymentsStart")}</p>
          <div className="flex flex-row justify-around gap-2">
            <Link to="/subscription" className="w-full">
              <Button className="basis-1/2 rounded-3xl text-emerald-600 bg-white">
                {t("change")}
              </Button>
            </Link>
            <Link to="/one-time-payments" className="w-full">
              <Button
                disabled={!isNotSubscribed}
                className={` ${
                  !isNotSubscribed && "bg-gray-300 text-gray-400"
                } basis-1/2 rounded-3xl text-emerald-600 bg-white`}
              >
                {t("enter")}
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex flex-col my-6 gap-4">
          {paymentLogs.length > 0 ? (
            paymentLogs.map((log) => (
                <div key={log.id} className="border-2 flex flex-col justify-center rounded-xl border-emerald-500">
                    <p className="text-lg font-semibold p-2">
                        {t("date")}{" "}
                    {new Date(log.created * 1000).toLocaleDateString()}
                    </p>
                    <div className="flex flex-row text-white py-2 bg-emerald-500 rounded-b-lg border-emerald-500 justify-between px-3">
                        <p className="text-lg font-semibold">
                            {t("amount")} {formatAmount(log.amount)} €
                        </p>
                        {log.status === "canceled" ? 
                            <div className="bg-white rounded-lg w-8 h-8 content-center">
                                <FaTimes className="bg-red-500 mx-auto p-1 rounded-full h-6 w-6" />
                            </div> 
                        : 
                            <div className="bg-white rounded-lg w-8 h-8 content-center">
                                <FaCheck className="bg-green-500 mx-auto p-1 rounded-full h-6 w-6" />
                            </div>
                        }
                    </div>
              </div>
            ))
          ) : (
            <p>{t("noPaymentsFound")}</p>
          )}
        </div>
      )}
    </div>
  );
};
