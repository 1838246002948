import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";

const getCustomer = async (customerId: string) => {
  try {
    const customerDocRef = doc(db, "customers", customerId);
    const customerDocSnap = await getDoc(customerDocRef);

    if (!customerDocSnap.exists()) {
      throw new Error("Customer not found");
    }

    const customerData = customerDocSnap.data();

    return {
      status: true,
      customer: { id: customerDocSnap.id, ...customerData },
    };
  } catch (error) {
    console.error("Error fetching customer:", error);
    return { status: false, message: "Failed to get customer", error };
  }
};

const getCustomerWithPayments = async (customerId: string) => {
  try {
    const customerDocRef = doc(db, "customers", customerId);
    const customerDocSnap = await getDoc(customerDocRef);

    if (!customerDocSnap.exists()) {
      throw new Error("Customer not found");
    }

    const customerData = customerDocSnap.data();

    const paymentsCollectionRef = collection(customerDocRef, "payments");
    const paymentsQuerySnapshot = await getDocs(paymentsCollectionRef);
    const paymentsData = paymentsQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      status: true,
      customer: {
        id: customerDocSnap.id,
        ...customerData,
        payments: paymentsData,
      },
    };
  } catch (error) {
    console.error("Error fetching customer with payments:", error);
    return {
      status: false,
      message: "Failed to get customer with payments",
      error,
    };
  }
};

const customersService = {
  getCustomer,
  getCustomerWithPayments,
};

export default customersService;
