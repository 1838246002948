import React, { useContext } from "react";
import { Button } from "../Button/Button";
import { LanguageContext } from "../../utils/LanguageProvider";
import Flag from 'react-world-flags';

export const LanguageSelector: React.FC = () => {
    const { updateLanguage, language } = useContext(LanguageContext);

    function changeLanguage(lang: string) {
        console.log(lang);
        updateLanguage(lang);
        localStorage.setItem("appLanguage", lang);
    }

    function handleClick(lang: string) {
        return () => changeLanguage(lang);
    }
    
    return (
        <div className="flex flex-row gap-3 h-16 justify-between my-3">
            <Button 
                value="en" 
                onClick={handleClick("en")} 
                className={`border-2 ${language === "en" && "border-emerald-500"} hover:bg-gray-200 w-[50%]`}
            >
                <Flag className="h-full mx-auto" code="GB" alt="English" />
            </Button>
            <Button 
                value="hr" 
                onClick={handleClick("hr")} 
                className={`border-2 ${language === "hr" && "border-emerald-500"} hover:bg-gray-200 w-[50%]`}
            >
                <Flag className="h-full mx-auto" code="HR" alt="Croatian" />
            </Button>
        </div>
    );
}
