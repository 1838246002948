import React, { useContext } from "react";
import { CategoryProps } from "../CardLevel/CardLevel";
import { CategoryItem } from "./CategoryItem";
import { LanguageContext } from "../../utils/LanguageProvider";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BackButton } from "../BackButton/BackButton";

interface CategoryComponentProps {
    category: CategoryProps;
}

export const CategoryComponent: React.FC<CategoryComponentProps> = ({category}) => {
    const { language } = useContext(LanguageContext);

    return(
        <div className="max-w-[900px] mx-auto container text-left md:text-center my-4 px-6">
            <div className="flex justify-start">
            <Link to="/">
                <BackButton className="w-10 h-10">
                    <FaArrowLeft className="mx-auto text-white"/>
                </BackButton>
            </Link>
            </div>
            <li className="text-yellow-300 font-medium">{category.categoryCode}</li>
            <h1 className="text-emerald-500 text-xl my-2 font-bold">{language === "en" ? category.categoryNameEn : category.categoryNameHr}</h1>
            {category.categoryPicture && (
                <img className="my-4 rounded-xl mx-auto" src={category.categoryPicture} alt={language === "en" ? category.categoryNameEn : category.categoryNameHr} />
            )}
            <p className="text-justify font-semibold whitespace-pre-line">{language === "en" ? category.categoryDescriptionEn : category.categoryDescriptionHr}</p>
            <div className="my-8 flex flex-col gap-4">
                <CategoryItem name={category.categoryCustom1} />
                <CategoryItem name={category.categoryCustom2} />
            </div>
        </div>
    )
}