import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import subscriptionService from "../api/subscriptionDescription";

interface GetSubscriptionsProps {
  options?: UseQueryOptions<any[], Error>;
}

export function useGetSubscriptions({ options }: GetSubscriptionsProps = {}) {
  return useQuery<any, Error>(
    ["getSubscriptions"],
    async () => {
      const { status, message, subscriptions } =
        await subscriptionService.getSubscriptions();

      if (status) {
        const sortedSubscriptions = subscriptions.sort((a: any, b: any) => {
          const orderMap = { Basic: 3, Advanced: 2, Expert: 1 };
          return orderMap[a.subscriptionCode] - orderMap[b.subscriptionCode];
        });

        return sortedSubscriptions;
      } else {
        throw new Error(message);
      }
    },
    options
  );
}
