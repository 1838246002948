import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import newsService from "../../news/api/newsApi";

interface GetNewsProps {
  newsTypes: string[];
  newsCodes?: string[];
  language: "en" | "hr";
  options?: UseQueryOptions<any[], Error>;
}

export function useGetNews({
  newsTypes,
  newsCodes,
  language,
  options,
}: GetNewsProps) {
  return useQuery<any[], Error>(
    ["getNews", newsCodes, newsTypes, language],
    async () => {
      const { status, message, news } = await newsService.getNewsByCodes(
        newsCodes
      );

      if (status) {
        if (newsTypes?.length) {
          const filteredNews = news?.filter((item) => {
            const categoryTitle =
              language === "hr"
                ? item?.category?.newsCategoryTitleHr
                : item?.category?.newsCategoryTitleEn;

            return newsTypes?.includes(categoryTitle);
          });
          return filteredNews as any[];
        } else return news;
      } else {
        throw new Error(message);
      }
    },
    options
  );
}
