import React, { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services";
import { LanguageContext } from "../utils/LanguageProvider";
import { FaArrowLeft } from "react-icons/fa";
import { BackButton } from "../components/BackButton/BackButton";
import { ItemProps } from "../components/CardLevel/CardLevel";

export const ItemPage: React.FC = () => {
    const { itemCode } = useParams<{ itemCode: string }>();
    const [item, setItem] = useState<ItemProps | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { language, t } = useContext(LanguageContext);

    useEffect(() => {
        const getItemDataByCode = async (itemCode: string) => {
            try {
                const itemsCollectionRef = collection(db, "items");
                const querySnapshot = await getDocs(itemsCollectionRef);

                const itemsData: ItemProps[] = querySnapshot.docs.map((doc) => doc.data() as ItemProps);

                console.log(itemsData)

                const matchedItem = itemsData.find((item) => item.itemCode === itemCode);
                if (matchedItem) {
                    setItem(matchedItem);
                } else {
                    setError("Item not found");
                    setItem(null);
                }
            } catch (error) {
                console.error("Error fetching item data:", error);
                setError("Failed to fetch item data");
                setItem(null);
            } finally {
                setLoading(false);
            }
        };

        if (itemCode) {
            getItemDataByCode(itemCode);
        }
    }, [itemCode]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="max-w-[900px] mx-auto container min-h-[calc(100vh-230px)] text-left md:text-center my-4 px-6">
            <div className="flex justify-start">
                <Link to="/my-stash">
                    <BackButton className="w-10 h-10">
                        <FaArrowLeft className="mx-auto text-white" />
                    </BackButton>
                </Link>
            </div>
            {item ? (
                <div>
                    <div className="bg-emerald-500 rounded-xl p-5">
                        {item.itemPicture && (
                            <img
                            className="mb-1 rounded-lg md:w-[400px] mx-auto"
                            src={item.itemPicture}
                            alt={language === "en" ? item.itemNameEn : item.itemNameHr}
                            />
                        )}
                        <p className="text-xs text-center">{t("pictureIsJustIllustration")}</p>
                        <h1 className="text-white text-center text-xl my-2 font-bold">
                            {language === "en" ? item.itemNameEn : item.itemNameHr}
                        </h1>
                    </div>
                    <p className="text-justify my-5 font-semibold whitespace-pre-line">
                        {language === "en" ? item.itemDescriptionEn : item.itemDescriptionHr}
                    </p>
                    <div className="my-8 flex flex-col gap-4">
                        <div>
                            <p className="font-semibold">{t("productDurability")}</p>
                            <p>{item.itemCustom1}</p>
                        </div>
                        <div>
                            <p className="font-semibold">{t("productComposition")}</p>
                            <p>{item.itemCustom2}</p>
                        </div>
                        <div>
                            <p className="font-semibold">{t("productRecommendedUsage")}</p>
                            <p>{item.itemCustom3}</p>
                        </div>
                        <div>
                            <p className="font-semibold">{t("productMaintenanceTips")}</p>
                            <p>{item.itemCustom4}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <p>{t("noItemFound")}: {itemCode}</p>
            )}
        </div>
    );
};
