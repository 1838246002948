import { db } from "../services";
import { addDoc, collection, doc, onSnapshot } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const createSubscriptionSession = async (
  priceId: string,
  setLoading: (loading: boolean) => void
) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("User is not authenticated");
    }

    const collectionRef = collection(
      db,
      "customers",
      user.uid,
      "checkout_sessions"
    );

    console.log(
      "Creating subscription session with priceId:",
      priceId,
      window.location.origin
    );

    const newDocRef = await addDoc(collectionRef, {
      price: priceId,
      mode: "subscription",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    onSnapshot(
      doc(db, "customers", user.uid, "checkout_sessions", newDocRef.id),
      (doc) => {
        const data = doc.data();
        if (!data) return;

        const { error, url } = data;
        if (error) {
          console.error("Error creating session:", error);
          alert(`Error: ${error.message}`);
          setLoading(false);
        }
        if (url) {
          setLoading(false);
          window.location.assign(url);
        }
      }
    );

    return true;
  } catch (error) {
    console.error("Failed to create subscription session:", error);
    alert(`Failed to create subscription session: ${error.message}`);
    setLoading(false);
    return false;
  }
};

export default createSubscriptionSession;
