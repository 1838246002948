import { collection, getDocs, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../services";
import { CardLevelProps, CategoryProps } from "../components/CardLevel/CardLevel";
import { CategoryComponent } from "../components/CategoryComponent/CategoryComponent";
import { Loader } from "../components/Loader/Loader";

const getCategoriesByCategoryCode = async (categoryCode: string): Promise<CategoryProps | null> => {
  try {
    const levelsCollectionRef = collection(db, "levels");
    const q = query(levelsCollectionRef);
    const querySnapshot = await getDocs(q);

    const levelsData: CardLevelProps[] = querySnapshot.docs
      .map((doc) => doc.data() as CardLevelProps)
      .filter((level) => level.categories && level.categories.length > 0);

    const seenCategoryCodes = new Set<string>();

    const filteredCategories = levelsData
      .map((level) =>
        level.categories.filter((category) => {
          if (!seenCategoryCodes.has(category.categoryCode)) {
            seenCategoryCodes.add(category.categoryCode);
            return category.categoryCode === categoryCode;
          } else {
            return false;
          }
        })
      )
      .flat();

    return filteredCategories.length > 0 ? filteredCategories[0] : null;
  } catch (error) {
    console.error("Error fetching category data:", error);
    return null;
  }
};

export const CategoryPage: React.FC = () => {
  const { categoryCode } = useParams<{ categoryCode: string }>();
  const [categoryData, setCategoryData] = useState<CategoryProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true);
      setError(null);

      if (categoryCode) {
        const data = await getCategoriesByCategoryCode(categoryCode);
        if (data) {
          setCategoryData(data);
          console.log(data)
        } else {
          setError("Category not found.");
        }
      } else {
        setError("Invalid parameters.");
      }

      setLoading(false);
    };

    fetchCategoryData();
  }, [categoryCode]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
   <CategoryComponent category={categoryData} />
  );
};
