import React, { useContext, useEffect, useState } from "react";
import { ProfileInfo } from "../components/ProfileInfo/ProfileInfo";
import { ProfilePayments } from "../components/ProfilePayments/ProfilePayments";
import { ProfileSites } from "../components/ProfileSites/ProfileSites";
import { LanguageSelector } from "../components/LanguageSelector/LanguageSelector";
import { UserDetailsContext } from "../utils/UserDetailsProvider";
import { Button } from "../components/Button/Button";
import { auth, db } from "../services/config/firebase";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../utils/LanguageProvider";
import { Loader } from "../components/Loader/Loader";
import { useGetActiveSubscription, useGetCustomer } from "../services";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  reauthenticateWithPopup,
  reauthenticateWithCredential,
  EmailAuthProvider,
  AuthCredential,
  deleteUser,
} from "firebase/auth";
import { doc, deleteDoc } from "firebase/firestore";

const reauthenticateUser = async (user: any, password?: string) => {
  try {
    if (user.providerData[0]?.providerId === "google.com") {
      const googleProvider = new GoogleAuthProvider();
      await reauthenticateWithPopup(user, googleProvider);
    } else if (user.providerData[0]?.providerId === "facebook.com") {
      const facebookProvider = new FacebookAuthProvider();
      await reauthenticateWithPopup(user, facebookProvider);
    } else if (user.providerData[0]?.providerId === "password" && password) {
      const credential: AuthCredential = EmailAuthProvider.credential(
        user.email,
        password
      );
      await reauthenticateWithCredential(user, credential);
    } else {
      throw new Error("Unsupported authentication provider");
    }
  } catch (error) {
    console.error("Reauthentication failed:", error);
    throw error;
  }
};

const deleteFirebaseUser = async (user: any) => {
  try {
    await deleteUser(user);
    console.log("User account deleted successfully from Firebase");
  } catch (error) {
    console.error("Error deleting user account from Firebase:", error);
    throw error;
  }
};

const deleteUserFromFirestore = async (userId: string) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await deleteDoc(userDocRef);
    console.log("User document deleted successfully from Firestore");
  } catch (error) {
    console.error("Error deleting user document from Firestore:", error);
    throw error;
  }
};

export const ProfilePage: React.FC = () => {
  const { userDetails, updateUserDetails } = useContext(UserDetailsContext);
  const navigate = useNavigate();
  const { t } = useContext(LanguageContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const { data: activeSubscription, isLoading: subscriptionFetchingLoading } =
    useGetActiveSubscription({ customerId: userDetails?.uid });
  const {
    data: uniqueCustomer,
    isLoading: customerFetchingLoading,
    error,
  } = useGetCustomer({ customerId: userDetails?.uid });

  useEffect(() => {
    const fetchData = async () => {
      if (!userDetails) {
        updateUserDetails();
      }
      setLoading(false);
    };

    fetchData();
  }, [userDetails, updateUserDetails]);

  const updateLevel = async () => {
    try {
      const customerId = uniqueCustomer?.id;
      if (customerId) {
        const result = await fetch(
          "https://us-central1-survstash-f265b.cloudfunctions.net/updateUserLevelManually",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: customerId }),
          }
        );
        const responseData = await result.json();
        console.log("Cloud Function Response:", responseData);
      }
    } catch (error) {
      console.error("Error calling Cloud Function:", error);
    }
  };

  useEffect(() => {
    if (uniqueCustomer?.id) {
      updateLevel();
    }
  }, [uniqueCustomer]);

  const logOut = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem("user");
      updateUserDetails(null);
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleDeleteAccount = async () => {
    if (!userDetails?.uid || !userDetails.email) return;

    try {
      setDeleting(true);

      const user = auth.currentUser;
      if (user) {
        if (user.providerData[0]?.providerId === "password") {
          await reauthenticateUser(user, password);
        } else {
          await reauthenticateUser(user);
        }

        await deleteFirebaseUser(user);

        await deleteUserFromFirestore(userDetails.uid);

        updateUserDetails(null);
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        console.error("User not found or password not provided");
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error deleting account:", error.message);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setDeleting(false);
      setIsModalOpen(false);
    }
  };

  const date = userDetails?.metadata?.creationTime
    ? new Date(userDetails.metadata.creationTime)
    : null;
  const formattedDate = date
    ? date.toLocaleDateString("en-GB").replace(/\//g, ".") + "."
    : "";

  return (
    <div className="m-4 container my-10 px-5 mx-auto max-w-[600px] min-h-[calc(100vh-200px)]">
      {!customerFetchingLoading ? (
        <ProfileInfo
          name={userDetails?.name}
          surname={userDetails?.lastName}
          level={userDetails?.level}
          subscription={activeSubscription?.role}
          dateJoined={formattedDate || ""}
          location={uniqueCustomer?.customerCustom3}
          rewardPoints={uniqueCustomer?.customerCustom2}
          bonusPoints={uniqueCustomer?.customerCustom1}
        />
      ) : (
        <Loader />
      )}
      <ProfilePayments />
      <ProfileSites />
      <LanguageSelector />
      <div className="flex flex-col my-6 gap-3">
        <Button
          onClick={logOut}
          className="rounded-xl text-white h-16 bg-yellow-500 hover:bg-yellow-600">
          {t("logOut")}
        </Button>
        <Button
          onClick={() => setIsModalOpen(true)}
          className="rounded-xl text-white h-16 bg-red-500 hover:bg-red-600">
          {t("deleteAccount")}
        </Button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg max-w-sm mx-auto">
            <h2 className="text-xl mb-4">{t("confirmDelete")}</h2>

            {userDetails?.providerData?.[0]?.providerId === "password" && (
              <>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  className="border border-gray-300 rounded p-2 mb-4 w-full"
                />
              </>
            )}

            <div className="flex justify-end gap-2">
              <Button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 hover:bg-gray-600 text-white">
                {t("cancelSubmission")}
              </Button>
              <Button
                onClick={handleDeleteAccount}
                className="bg-red-500 hover:bg-red-600 text-white">
                {deleting ? t("deleting") : t("deleteAccount")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
